import { useEffect, useContext } from "react";
import { CButton } from "@coreui/react";
import ReactHtmlParser from "react-html-parser";
import { TakeCourseContext } from "../../../TakeCourseContext";
import { hasAnsweredAllQuizQuestionsOfModule } from "./helpers/hasAnsweredAllQuizQuestionsOfModule";

export default function QuizMultipleChoice(props: any) {
  const {
    question,
    setQuestionDisplay,
    displayId,
    quizId,
    nextQuestion,
    previousQuestion,
  } = props;
  const {
    courseProgress,
    setCourseProgress,
    setAllowedNext,
    selectedModule,
    saveProgress,
    module_id,
  } = useContext(TakeCourseContext);

  useEffect(() => {
    setQuestionDisplay(question.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAnswer = (studentAnswer: any, questionId: any) => {
    setCourseProgress((progress: any) => {
      const newCourseProgress = {
        ...progress,
        course_progress: {
          ...progress.course_progress,
          [module_id]: {
            ...progress.course_progress[module_id],
            [quizId]: {
              ...progress.course_progress?.[module_id]?.[quizId],
              [questionId]: {
                answer: question.answer,
                choice: studentAnswer,
              },
            },
          },
        },
      };
      setAllowedNext(
        hasAnsweredAllQuizQuestionsOfModule(
          selectedModule,
          module_id,
          newCourseProgress
        )
      );
      saveProgress(newCourseProgress);
      return newCourseProgress;
    });
  };

  return (
    <div
      id="quizMultipleChoice"
      className="d-flex flex-column h-100 w-100"
      style={{
        background: "linear-gradient(0.375turn, #1b2c5f, #0562A5)",
        borderRadius: "10px",
      }}
    >
      <div
        className="d-flex justify-content-end"
        style={{
          overflow: "auto",
          padding: "2%",
        }}
      >
        {selectedModule.quizContent.map((ques: any) => {
          return (
            <CButton
              id={question.id}
              key={ques.id}
              color={displayId === ques.id ? "secondary" : "primary"}
              size="sm"
              alt="Question Number"
              className="mx-1"
              onClick={() => setQuestionDisplay(ques.id)}
              style={{
                alignSelf: "center",
              }}
            >
              {selectedModule.quizContent.findIndex(
                (ques2: any) => ques2.id === ques.id
              ) + 1}
            </CButton>
          );
        })}
      </div>
      <div
        className="w-100"
        style={{
          height: "100%",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {ReactHtmlParser(question.content)}
        <div className="w-100">
          <div
            className="d-flex w-100 align-items-center justify-content-center"
            style={{
              height: question.quizType === "multipleChoice" ? "" : "100%",
              alignSelf: "flex-end",
            }}
          >
            <CButton
              id="answer-a"
              onClick={(e: any) => setAnswer(e.target.id, question.id)}
              className="m-2"
              type="text"
              size="lg"
              style={{
                backgroundColor:
                  courseProgress.course_progress?.[module_id]?.[quizId]?.[
                    question.id
                  ]?.choice === "answer-a"
                    ? "#F4A71F"
                    : "#0275d8",
                textAlign: "center",
                fontSize: "1rem",
                color: "white",
                border: "none",
                borderRadius: "10px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {question.options["answer-a"]}
            </CButton>
            <CButton
              id="answer-b"
              onClick={(e: any) => setAnswer(e.target.id, question.id)}
              className="m-2"
              type="text"
              size="lg"
              style={{
                backgroundColor:
                  courseProgress.course_progress?.[module_id]?.[quizId]?.[
                    question.id
                  ]?.choice === "answer-b"
                    ? "#F4A71F"
                    : "#0275d8",

                textAlign: "center",
                fontSize: "1rem",
                color: "white",
                border: "none",
                borderRadius: "10px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {question.options["answer-b"]}
            </CButton>
          </div>
          <div
            className="w-100 h-100 align-items-center justify-content-center"
            style={{
              display: question.quizType === "multipleChoice" ? "flex" : "none",
            }}
          >
            <CButton
              id="answer-c"
              onClick={(e: any) => setAnswer(e.target.id, question.id)}
              className="m-2"
              type="text"
              size="lg"
              style={{
                backgroundColor:
                  courseProgress.course_progress?.[module_id]?.[quizId]?.[
                    question.id
                  ]?.choice === "answer-c"
                    ? "#F4A71F"
                    : "#0275d8",
                textAlign: "center",
                fontSize: "1rem",
                color: "white",
                border: "none",
                borderRadius: "10px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {question.options["answer-c"]}
            </CButton>
            <CButton
              id="answer-d"
              onClick={(e: any) => setAnswer(e.target.id, question.id)}
              className="m-2"
              type="text"
              size="lg"
              style={{
                backgroundColor:
                  courseProgress.course_progress?.[module_id]?.[quizId]?.[
                    question.id
                  ]?.choice === "answer-d"
                    ? "#F4A71F"
                    : "#0275d8",
                textAlign: "center",
                fontSize: "1rem",
                color: "white",
                border: "none",
                borderRadius: "10px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {question.options["answer-d"]}
            </CButton>
          </div>
          <div className="d-flex justify-content-center w-100 mb-2 mt-4">
            <CButton
              color="secondary"
              disabled={
                selectedModule.quizContent.findIndex(
                  (ques: any) => ques.id === question.id
                ) === 0
              }
              className="m-2 w-100 text-light"
              onClick={() => previousQuestion(question.id)}
            >
              Previous Question
            </CButton>
            <CButton
              color="secondary"
              className="m-2 w-100 text-light"
              disabled={
                selectedModule.quizContent.length - 1 ===
                selectedModule.quizContent.findIndex(
                  (ques: any) => ques.id === question.id
                )
              }
              onClick={() => nextQuestion(question.id)}
            >
              Next Question
            </CButton>
          </div>
        </div>
      </div>
    </div>
  );
}
