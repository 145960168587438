import { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Get_API } from "../../../../API/Get_API";
import { Prompt, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ParamTypes } from "../../Customer/CustomerView";
import { IState, Course } from "../../../../store";
import { CourseContext } from "./CourseContext";
import Loading from "../../../../components/Loading";
import CourseTitle from "./Details/CourseTitle";
import InsertCustomer from "./Details/AssignCustomer";
import AssignStudents from "./Details/AssignStudents";
import TypeOfModule from "./Details/TypeOfModule";
import NewCourseTemplate from "./Details/NewCourseTemplate";
import CreateCourseButtons from "./Details/CreateCourseButtons";
import CourseModules, { CourseModule } from "./Details/CourseModules";
import { CContainer } from "@coreui/react";
import styled from "styled-components";
import LayoutSelector from "./Details/LayoutSelector";

export default function EditCourse() {
  const { getAccessTokenSilently } = useAuth0();
  const { _id } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const courses = useSelector((state: IState) => state.courses);
  const course = useSelector((state: IState) =>
    state.courses?.find((c) => c._id === _id)
  );
  const [moduleSelected, setModuleSelected] = useState<string>(
    course?.modules[0].id!
  );
  const module = course?.modules.find(
    (mod: CourseModule) => mod.id === moduleSelected
  );
  const [displayId, setDisplayId] = useState(module?.quizContent[0].id);
  const [edited, setEdited] = useState(false);

  const getCourse = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const courses = await Get_API(`/api/courses?course_id=${_id}`, token);
    if (courses.success) {
      dispatch({ type: "set", courses: courses.success });
    }
  }, [dispatch, getAccessTokenSilently, _id]);

  useEffect(() => {
    if (!course) {
      getCourse();
    }
  }, [getCourse, course]);

  const setCourse = (course: Course) => {
    setEdited(true);
    if (courses) {
      dispatch({
        type: "set",
        courses: [...courses.filter((c) => c._id !== _id), course],
      });
    } else {
      dispatch({
        type: "set",
        courses: [course],
      });
    }
  };

  if (!course) {
    return <Loading />;
  }

  return (
    <CourseContext.Provider
      value={{
        course,
        setCourse,
        moduleSelected,
        setModuleSelected,
        module,
        displayId,
        setDisplayId,
        courses,
        setEdited,
      }}
    >
      <div className="d-flex flex-row w-100 align-items-start p-2">
        <div
          className="d-flex flex-row w-25 h-100 justify-content-center align-items-start"
          style={{
            overflow: "auto",
          }}
        >
          <CourseModules />
        </div>
        <div className="d-flex flex-column w-75 h-100 justify-content-start align-items-start">
          <CourseTitle />
          <StyledAssignCContainer>
            <InsertCustomer />
            <AssignStudents />
          </StyledAssignCContainer>
          <NewCourseTemplate />
          <div className="d-flex flex-row w-100 justify-content-center align-items-start">
            <div className="d-flex flex-row w-50">
              <TypeOfModule />
              <LayoutSelector />
            </div>
            <div className="d-flex w-50 justify-content-end">
              <CreateCourseButtons />
            </div>
          </div>
        </div>
      </div>
      <Prompt
        when={edited}
        message="Are you sure you want to leave without saving?"
      />
    </CourseContext.Provider>
  );
}

const StyledAssignCContainer = styled(CContainer)`
  padding: 1rem 0;
  margin: 0;
  display: flex;
  flex-direction: row:
`;
