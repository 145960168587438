import { useState, useEffect, useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { CInput, CButton, CSelect } from "@coreui/react";
import { v4 as uuidv4 } from "uuid";
import { CourseContext } from "../../CourseContext";

require("dotenv").config();

export interface QuizMultipleChoiceProps {
  question: any;
}

const QuizMultipleChoice: React.FC<QuizMultipleChoiceProps> = ({
  question,
}) => {
  const { course, setCourse, module, displayId, setDisplayId, moduleSelected } =
    useContext(CourseContext);
  const [deleteMode, setDeleteMode] = useState(false);

  useEffect(() => {
    setDisplayId(question.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAnswer = (answer: any, id: any) => {
    let indexOfQuestion = module.quizContent.findIndex(
      (ques: any) => ques.id === id
    );
    module.quizContent[indexOfQuestion].answer = answer;

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );

    course.modules.splice(indexOfModule, 1, module);
    setCourse(course);
  };

  const handleEditorChange = (content: any, id: any) => {
    let indexOfQuestoin = module.quizContent.findIndex(
      (ques: any) => ques.id === id
    );

    module.quizContent[indexOfQuestoin].content = content;

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );

    course.modules.splice(indexOfModule, 1, module);

    setCourse(course);
  };

  const updateAnswers = (e: any, id: any) => {
    const indexOfQuestion = module.quizContent.findIndex(
      (ques: any) => ques.id === id
    );

    module.quizContent[indexOfQuestion].options[e.target.id] = e.target.value;

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === course.id
    );

    course.modules.splice(indexOfModule, 1, module);

    setCourse(course);
  };

  const handleQuizType = (quizType: any, id: any) => {
    let indexOfQuestion = module.quizContent.findIndex(
      (ques: any) => ques.id === id
    );

    let updatorQuestion = { ...module.quizContent[indexOfQuestion] };

    updatorQuestion.quizType = quizType;

    module.quizContent.splice(indexOfQuestion, 1, updatorQuestion);

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );

    course.modules.splice(indexOfModule, 1, module);

    setCourse(course);
  };

  const addNewQuestion = () => {
    if (module.quizContent.length < 7) {
      let newQuestion = {
        quizType: "multipleChoice",
        id: uuidv4(),
        content: "",
        answer: "answer-a",
        options: {
          "answer-a": "True",
          "answer-b": "False",
          "answer-c": "Sometimes",
          "answer-d": "Never",
        },
      };

      let indexOfModule = course.modules.findIndex(
        (mod: any) => mod.id === moduleSelected
      );

      module.quizContent.push(newQuestion);

      course.modules.splice(indexOfModule, 1, module);
      setCourse(course);
      setDisplayId(newQuestion.id);
    } else {
      window.alert("No more than 7 questions per quiz, for styling reasons.");
    }
  };

  const deleteQuestion = (id: any) => {
    if (module.quizContent.length > 1) {
      module.quizContent = module.quizContent.filter(
        (section: any) => section.id !== id
      );
      setDisplayId(module.quizContent[0].id);

      let indexOfModule = course.modules.findIndex(
        (mod: any) => mod.id === module.id
      );

      course.modules.splice(indexOfModule, 1, module);
      setCourse(course);
    } else {
      window.alert("You must have at least one question...");
    }
  };

  return (
    <div className="h-100 w-100">
      <div
        className="d-flex justify-content-end"
        style={{
          backgroundColor: "rgb(57, 155, 232)",
          overflow: "auto",
        }}
      >
        {module.quizContent.map((ques: any) => {
          return (
            <CButton
              id={question.id}
              key={ques.id}
              color={
                deleteMode && displayId === ques.id
                  ? "danger"
                  : question.id === ques.id
                  ? "success"
                  : "primary"
              }
              size="sm"
              alt="Question Number"
              className="mx-1"
              onMouseEnter={() => {
                if (displayId === ques.id) {
                  setDeleteMode(true);
                }
              }}
              onMouseLeave={() => {
                if (displayId === ques.id) {
                  setDeleteMode(false);
                }
              }}
              onClick={() =>
                deleteMode && ques.id === displayId
                  ? deleteQuestion(ques.id)
                  : setDisplayId(ques.id)
              }
              style={{
                alignSelf: "center",
              }}
            >
              {deleteMode && displayId === ques.id ? (
                <small>X</small>
              ) : (
                module.quizContent.findIndex(
                  (ques2: any) => ques2.id === ques.id
                ) + 1
              )}
            </CButton>
          );
        })}
        <CButton
          color="primary"
          size="sm"
          alt="Question Number"
          className="mx-1"
          onClick={addNewQuestion}
          style={{
            alignSelf: "center",
          }}
        >
          +
        </CButton>
        <CSelect
          className="h-100 m-3"
          style={{
            backgroundColor: "#007CEE",
            border: "none",
            color: "white",
            width: "33%",
          }}
          value={question.quizType}
          onChange={(e: any) => handleQuizType(e.target.value, question.id)}
        >
          <option value={"multipleChoice"}>Multiple Choice</option>
          <option value={"trueFalse"}>True or False</option>
        </CSelect>
      </div>
      <div className="w-100">
        <Editor
          onEditorChange={(e) => handleEditorChange(e, question.id)}
          value={question.content}
          apiKey={process.env.REACT_APP_TINY_KEY}
          init={{
            width: "100%",
            height: "190px",
            resize: true,
            plugins: [
              "advlist autolink lists link image",
              "charmap print preview anchor help",
              "searchreplace visualblocks code",
              "insertdatetime media table paste wordcount",
            ],
            menubar: "file edit view format",
            toolbar:
              // eslint-disable-next-line no-multi-str
              "undo redo | formatselect | bold italic | \
                        alignleft aligncenter alignright | \
                        bullist numlist outdent indent | help",
          }}
        />
      </div>
      <div>
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{
            height: question.quizType === "multipleChoice" ? "" : "100%",
          }}
        >
          <CInput
            id="answer-a"
            onClick={(e: any) => setAnswer(e.target.id, question.id)}
            onChange={(e: any) => updateAnswers(e, question.id)}
            className="m-2"
            type="text"
            value={question.options["answer-a"]}
            style={{
              height: "30px",
              textAlign: "center",
              fontSize: "1.75em",
              backgroundColor:
                question.answer === "answer-a" ? "#5cb85c" : "rgb(0, 112, 185)",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
          />
          <CInput
            id="answer-b"
            onClick={(e: any) => setAnswer(e.target.id, question.id)}
            onChange={(e: any) => updateAnswers(e, question.id)}
            className="m-2"
            type="text"
            value={question.options["answer-b"]}
            style={{
              height: "30px",
              textAlign: "center",
              fontSize: "1.75em",
              backgroundColor:
                question.answer === "answer-b" ? "#5cb85c" : "rgb(0, 112, 185)",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
          />
        </div>
        <div
          className="w-100 h-100 align-items-center justify-content-center"
          style={{
            display: question.quizType === "multipleChoice" ? "flex" : "none",
          }}
        >
          <CInput
            id="answer-c"
            onClick={(e: any) => setAnswer(e.target.id, question.id)}
            onChange={(e: any) => updateAnswers(e, question.id)}
            className="m-2"
            type="text"
            value={question.options["answer-c"]}
            style={{
              height: "30px",
              textAlign: "center",
              fontSize: "1.75em",
              backgroundColor:
                question.answer === "answer-c" ? "#5cb85c" : "rgb(0, 112, 185)",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
          />
          <CInput
            id="answer-d"
            onClick={(e: any) => setAnswer(e.target.id, question.id)}
            onChange={(e: any) => updateAnswers(e, question.id)}
            className="m-2"
            type="text"
            value={question.options["answer-d"]}
            style={{
              height: "30px",
              textAlign: "center",
              fontSize: "1.75em",
              backgroundColor:
                question.answer === "answer-d" ? "#5cb85c" : "rgb(0, 112, 185)",
              color: "white",
              border: "none",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizMultipleChoice;
