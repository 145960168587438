import { CImg, CSpinner } from "@coreui/react";
import styled from "styled-components";

const Loading = () => {
  return (
    <StyledLoadingDiv>
      <CImg src={`${process.env.PUBLIC_URL}/logo.png`} height="20%" />
      <CSpinner className="position-absolute" style={{ bottom: "20px" }} />
    </StyledLoadingDiv>
  );
};

export default Loading;

const StyledLoadingDiv = styled.div`
  background-color: rgb(0, 25, 74);
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
