import { useState, useContext } from "react";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CInput,
  CButton,
  CSwitch,
  CTooltip,
} from "@coreui/react";
import { CourseContext } from "../CourseContext";
import CIcon from "@coreui/icons-react";

const CopyIframe: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [iframeMode, setIframeMode] = useState(false);
  const { course } = useContext(CourseContext);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <CTooltip content="Share Course">
        <CButton
          size="sm"
          color="primary"
          className="mx-1 w-100"
          onClick={toggle}
        >
          <CIcon name="cil-share" />
        </CButton>
      </CTooltip>
      <CModal show={modal} onClose={toggle}>
        <CModalHeader
          style={{
            border: "none",
          }}
        >
          Copy Iframe/Course Link
        </CModalHeader>
        <CModalBody>
          <CInput
            id="copyLinkAndIframe"
            type="textarea"
            value={
              iframeMode
                ? `<iframe src=“${window.location.protocol}//${window.location.hostname}/course/${course._id}” title=“${course.courseTitle}” width="300px" height="300px" ></iframe>`
                : `${window.location.protocol}//${window.location.hostname}/course/${course._id}`
            }
            style={{
              backgroundColor: "#8BB6DB",
              borderRadius: "10px",
              border: "none",
              color: "white",
            }}
          />
        </CModalBody>
        <CModalFooter
          style={{
            border: "none",
          }}
        >
          <CTooltip
            content={iframeMode ? "Copy The Iframe" : "Copy The Course Link"}
          >
            <CSwitch
              color="secondary"
              onChange={() => setIframeMode(!iframeMode)}
            ></CSwitch>
          </CTooltip>
          <CButton
            size="md"
            color="success"
            alt="Copy url/iframe."
            className="my-2"
            onClick={() => {
              let copyText: HTMLInputElement =
                document.querySelector("#copyLinkAndIframe")!;
              copyText!.select();
              document.execCommand("copy");
            }}
          >
            Copy
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default CopyIframe;
