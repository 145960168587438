import { CourseModule } from "../../../../../Admin/Courses/Course/Details/CourseModules";

export const hasAnsweredAllQuizQuestionsOfModule = (
  selectedModule: CourseModule,
  module_id: string,
  courseProgress: any
) => {
  if (selectedModule && courseProgress) {
    if (selectedModule.moduleType === "quiz") {
      const totalQuestions = selectedModule.quizContent.length;
      const quizId = Object.keys(
        courseProgress.course_progress?.[module_id] ?? {}
      );
      console.log(quizId);
      const answeredQuestions = Object.keys(
        courseProgress.course_progress?.[module_id][quizId[0]] ?? {}
      ).length;

      if (totalQuestions === answeredQuestions) {
        return true;
      } else {
        return false;
      }
    }
  }
  return true;
};
