export const Post_API = async (route: string, body: {}, token: string) => {
  if (process.env.NODE_ENV !== "production") {
    console.log("POST => ", route);
  }
  try {
    const response = await fetch(
      process.env.REACT_APP_SERVER_ROOT_URL + route,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    );
    const responseData = await response.json();
    if (process.env.NODE_ENV !== "production") {
      console.log("RESPONSE => ", responseData);
    }
    if (responseData.success) {
      return responseData;
    } else if (responseData.error) {
      window.alert(responseData.error);
      console.log(responseData);
    }
  } catch (error) {
    console.log(error);
  }
};
