import React, { useState, useEffect, useCallback, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CContainer,
  CTooltip,
} from "@coreui/react";
import { IState, Student } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { Get_API } from "../../../../../API/Get_API";
import Select from "react-select";
import { CourseContext } from "../CourseContext";
import emailjs from "emailjs-com";

const AssignStudents: React.FC = () => {
  const [modal, setModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [groups, setGroups] = useState<any[]>([]);
  const { course, setCourse } = useContext(CourseContext);
  const [loading, setLoading] = useState(false);
  const students = useSelector((state: IState) => state.students);

  const toggle = () => {
    setModal(!modal);
  };

  const getStudents = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const students = await Get_API(
      `/api/students?customer_id=${course.customer_id}`,
      token
    );
    if (students.success) {
      dispatch({ type: "set", students: students.success });
      let student_groups = new Set();
      students.success.forEach((stud: Student) =>
        student_groups.add(stud.group)
      );
      setGroups([...student_groups]);
    }
  }, [dispatch, getAccessTokenSilently, course.customer_id]);

  useEffect(() => {
    if (course.customer_id) {
      getStudents();
    }
  }, [getStudents, course.customer_id]);

  const emailStudents = async () => {
    if (students) {
      setLoading(true);
      for (const student of students) {
        if (course.reccomended_groups.includes(student.group)) {
          try {
            const token = await getAccessTokenSilently();
            //Assign Students
            await Get_API(
              `/api/course-progress?user_email=${student.email}&course_id=${course?._id}`,
              token
            );

            //Email Students
            await emailjs.send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID!,
              "template_3680j4e",
              {
                to_email: student.email,
                to_name: `${student.first_name} ${student.last_name}`,
                course_title: course.course_title,
                course_url: `https://app.crisischampion.com/course/${course._id}`,
              },
              process.env.REACT_APP_EMAILJS_USER_ID
            );
          } catch (error) {
            console.log(error);
            window.alert(
              "One or more students were unable to get assigned this course."
            );
          }
        }
      }
      window.alert("Assigned!");
      setLoading(false);
    }
  };

  return (
    <CContainer className="p-0 ml-2">
      <CButton
        onClick={toggle}
        color="primary"
        className="w-100"
        size="md"
        disabled={!course.customer_id}
      >
        Assign to Group{" "}
        {course.reccomended_groups.length > 0 &&
          `(${course.reccomended_groups.length})`}
      </CButton>
      <CModal show={modal} onClose={toggle}>
        <CModalHeader
          style={{
            border: "none",
            paddingTop: "0px",
          }}
        />
        <CModalBody className="d-flex flex-column justify-content-center">
          <span className="modalTitle text-center">Select Group</span>
          <Select
            styles={{
              menuList: (provided, state) => ({
                ...provided,
                color: "black",
              }),
            }}
            isMulti
            value={
              course.reccomended_groups
                ? course.reccomended_groups.map((group: string) => {
                    return {
                      label: group,
                      value: group,
                    };
                  })
                : []
            }
            onChange={(e) => {
              setCourse({
                ...course,
                reccomended_groups: e.map(
                  (group: { label: string; value: string }) => {
                    return group.value;
                  }
                ),
              });
            }}
            options={
              groups &&
              groups.map((group) => {
                return {
                  label: group,
                  value: group,
                };
              })
            }
          />
        </CModalBody>
        <CModalFooter
          style={{
            border: "none",
          }}
        >
          <CTooltip content="Assign and notifiy students by email.">
            <CButton
              color="success"
              size="md"
              onClick={emailStudents}
              disabled={loading}
            >
              Assign Students To This Course
            </CButton>
          </CTooltip>

          <CButton color="primary" size="md" onClick={toggle}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </CContainer>
  );
};

export default AssignStudents;
