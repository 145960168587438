import { CButton, CTooltip } from "@coreui/react";
import { FC } from "react";
import CIcon from "@coreui/icons-react";

export interface SelectTypeButtonProps {
  icon: string;
  tooltipContent: string;
  onClick: () => void;
}

export const SelectTypeButton: FC<SelectTypeButtonProps> = ({
  icon,
  tooltipContent,
  onClick,
}) => {
  return (
    <CTooltip content={tooltipContent}>
      <CButton
        size="sm"
        color="secondary"
        className="mx-1"
        style={{ height: "auto" }}
        onClick={onClick}
      >
        <CIcon
          color="primary"
          style={{ color: "white" }}
          name={icon}
          size="lg"
        />
      </CButton>
    </CTooltip>
  );
};
