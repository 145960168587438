import { useContext } from "react";
import { CSelect } from "@coreui/react";
import { CourseContext } from "../CourseContext";
import { CourseModule } from "./CourseModules";

export default function TypeOfModule() {
  const { moduleSelected, setCourse, course } = useContext(CourseContext);

  const updateModuleType = (newModuleType: string) => {
    let module = course.modules.find(
      (module: CourseModule) => module.id === moduleSelected
    );

    module.moduleType = newModuleType;

    if (newModuleType === "quiz") {
      module.moduleLayout = "quiz";
    } else if (newModuleType === "video") {
      module.moduleLayout = "layout2";
    } else if (newModuleType === "textAndPicture") {
      module.moduleLayout = "layout3";
    }

    let indexOfModule = course.modules.findIndex(
      (mod: CourseModule) => mod.id === moduleSelected
    );

    course.modules = course.modules.filter((mod: CourseModule) => {
      return mod.id !== moduleSelected;
    });

    course.modules.splice(indexOfModule, 0, module);

    setCourse(course);
  };

  if (!course) {
    return null;
  }

  return (
    <div className="m-2">
      <CSelect
        value={
          course.modules?.find(
            (module: CourseModule) => module.id === moduleSelected
          )?.moduleType
        }
        onChange={(e: any) => {
          updateModuleType(e.target.value);
        }}
        className="my-2"
        style={{
          backgroundColor: "#89B5DD",
          color: "white",
        }}
      >
        <option value="textAndPicture">Text and Picture</option>
        <option value="video">Video</option>
        <option value="quiz">Quiz</option>
      </CSelect>
    </div>
  );
}
