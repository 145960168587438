import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer, CFade } from "@coreui/react";

// routes config
import routes from "../routes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheContent = () => {
  return (
    <main className="c-main d-flex flex-column">
      <CContainer fluid className="d-flex h-100 align-items-center">
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    render={(props: any) => (
                      <CFade className="w-100">
                        <route.component {...props} />
                      </CFade>
                    )}
                  />
                )
              );
            })}
            <Redirect from="/" to="/" />
          </Switch>
        </Suspense>
      </CContainer>
      <span>
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          height={"15px"}
          className="m-2"
          alt="Crisis Logo"
        />
        Copyright © 2021 Crisis Champion LLC. All Rights Reserved.
      </span>
    </main>
  );
};

export default React.memo(TheContent);
