import { createContext } from "react";
import { Course } from "../../../../store";

export interface CourseContextValues {
  course: Course;
  setCourse: (course: Course) => void;
  values: any;
}

export const CourseContext = createContext<any>(null);
