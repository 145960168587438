import { useContext } from "react";
import Template3 from "./templates/Template_3";
import Template2 from "./templates/Template_2";
import TemplateVideo from "./templates/Template_Video";
import Template1 from "./templates/Template_1";
import TemplateQuiz from "./templates/Template_Quiz";
import { CourseContext } from "../CourseContext";

const NewCourseTemplate = () => {
  const { module } = useContext(CourseContext);

  const layout = module && module.moduleLayout;

  if (!layout) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "400px", width: "100%", backgroundColor: "#5AB3F7" }}
      >
        <h2>Select a module to get started.</h2>
      </div>
    );
  }

  return (
    <div className="d-flex" style={{ height: "400px", width: "100%" }}>
      {layout === "layout3" && <Template3 />}
      {layout === "layout2" && <Template2 />}
      {layout === "layout-vid" && <TemplateVideo />}
      {layout === "layout-vidText" && <Template1 />}
      {layout === "quiz" && <TemplateQuiz />}
    </div>
  );
};

export default NewCourseTemplate;
