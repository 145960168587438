import {
  CButton,
  CCollapse,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
} from "@coreui/react";
import { Formik } from "formik";
import { FC, useState } from "react";
import Cropper from "react-easy-crop";

export interface CropperCollapseProps {
  inputImg: any;
  showCropper: boolean;
  showModal: boolean;
  handleUpload: (v: any) => void;
}

export const CropperCollapse: FC<CropperCollapseProps> = ({
  inputImg,
  showCropper,
  showModal,
  handleUpload,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState({ w: 1, h: 1 });

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };

  const onCropComplete = async (_: any, croppedAreaPixels: any) => {
    const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels);
    console.log(croppedImage);
    return croppedImage;
  };
  const onZoomChange = (zoom: number) => {
    setZoom(zoom);
  };

  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc: any, crop: any) => {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    //DEFINE WIDTH AND HEIGHT OTHERWISE IMAGE IS NOT RIGHT SIZE

    if (ctx) {
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
    }

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg");
    });
  };

  return (
    <CCollapse show={showCropper} className="my-2">
      <Formik
        initialValues={{
          file: {} as HTMLInputElement,
        }}
        onSubmit={(values) => handleUpload(values)}
      >
        {(form) => (
          <>
            <CContainer
              className="position-relative"
              style={{ height: "60vh", width: "75%", position: "relative" }}
            >
              {showModal && showCropper && (
                <Cropper
                  image={inputImg}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect.w / aspect.h}
                  onCropChange={onCropChange}
                  onCropComplete={async (_, cropped) => {
                    const blob = await onCropComplete(_, cropped);
                    return form.setFieldValue("file", blob);
                  }}
                  onZoomChange={onZoomChange}
                />
              )}
            </CContainer>
            <CContainer className="d-flex px-0 w-75">
              <CInputGroup>
                <CInputGroupAppend>
                  <CInputGroupText style={{ borderBottomLeftRadius: "3px" }}>
                    Aspect W
                  </CInputGroupText>
                </CInputGroupAppend>
                <CInput
                  className="rounded-0"
                  type="number"
                  value={aspect.w}
                  name="imageDimensions.width"
                  onChange={(e: any) =>
                    setAspect({ ...aspect, w: e.target.value })
                  }
                />
              </CInputGroup>
              <CInputGroup>
                <CInputGroupAppend>
                  <CInputGroupText>Aspect H</CInputGroupText>
                </CInputGroupAppend>
                <CInput
                  className="rounded-0"
                  type="number"
                  value={aspect.h}
                  name="imageDimensions.height"
                  onChange={(e: any) =>
                    setAspect({ ...aspect, h: e.target.value })
                  }
                />
              </CInputGroup>
              <CButton
                color="success"
                className="w-75"
                style={{
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                onClick={form.handleSubmit}
              >
                Save New Version
              </CButton>
            </CContainer>
          </>
        )}
      </Formik>
    </CCollapse>
  );
};
