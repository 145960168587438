export const Get_API = async (route: string, token: string) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_SERVER_ROOT_URL + route,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseData = await response.json();
    if (process.env.NODE_ENV !== "production") {
      console.log("GET => ", route, responseData);
    }
    if (responseData.success) {
      return responseData;
    } else {
      console.log(responseData);
    }
  } catch (error) {
    console.log(error);
  }
};
