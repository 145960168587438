import { FC } from "react";
import ReactHtmlParser from "react-html-parser";

export interface TextContentProps {
  content: string;
}

export const TextContent: FC<TextContentProps> = ({ content }) => {
  return (
    <div
      className="d-flex flex-column w-100 justify-content-center align-items-center m-2"
      style={{
        color: "white",
      }}
    >
      {ReactHtmlParser(content)}
    </div>
  );
};
