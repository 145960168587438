import React, { useContext } from "react";
import { CourseContext } from "../CourseContext";
import { CourseModule } from "./CourseModules";
import {
  CImg,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";

export default function LayoutSelector() {
  let { module, moduleSelected, course, setCourse } = useContext(CourseContext);

  const setLayout = (type: string) => {
    course.modules.find(
      (mod: CourseModule) => mod.id === moduleSelected
    ).moduleLayout = type;
    setCourse(course);
  };

  const moduleTypes = [
    {
      name: "layout3",
      allowed: ["textAndPicture"],
      image: "layout-3.png",
    },
    {
      name: "layout2",
      allowed: ["textAndPicture", "video"],
      image: "layout-2.png",
    },
    {
      name: "layout-vid",
      allowed: ["textAndPicture", "video"],
      image: "layout-1.png",
    },
    {
      name: "layout-vidText",
      allowed: ["textAndPicture"],
      image: "layout-vid.png",
    },
  ];

  return (
    <div className="d-flex flex-column m-2">
      {
        <CDropdown>
          <CDropdownToggle
            color="primary my-2"
            size="sm"
            style={{ height: "35px" }}
          >
            Layout
          </CDropdownToggle>
          <CDropdownMenu>
            {moduleTypes.map((type) => {
              return (
                <CDropdownItem
                  key={type.name}
                  onClick={() => setLayout(type.name)}
                  color={
                    module && module.moduleLayout === type.name
                      ? "primary"
                      : "secondary"
                  }
                  disabled={module && !type.allowed.includes(module.moduleType)}
                >
                  <CImg
                    src={`${process.env.PUBLIC_URL}/${type.image}`}
                    className="m-auto"
                  />
                </CDropdownItem>
              );
            })}
          </CDropdownMenu>
        </CDropdown>
      }
    </div>
  );
}
