import { ContentEditor } from "./ContentEditor";
import { useContext } from "react";
import { CourseContext } from "../../CourseContext";

export default function TemplateVideo() {
  const { module } = useContext(CourseContext);

  return (
    <div
      className="d-flex"
      style={{
        backgroundColor: "#5AB3F7",
        height: "100%",
        width: "100%",
        borderRadius: "20px",
        overflow: "auto",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center w-100 m-3"
        style={{
          backgroundColor:
            module.moduleContent1 === "text" || module.moduleContent1 === ""
              ? "#0278CF"
              : "",
          borderRadius: "3px",
          overflow: "hidden",
        }}
      >
        <ContentEditor layoutSlot={"moduleContent1"} />
      </div>
    </div>
  );
}
