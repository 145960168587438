import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { CourseModule } from "./pages/Admin/Courses/Course/Details/CourseModules";

export interface newState {
  type: string;
  rest: any;
}

export interface Admin {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface Customer {
  student: string[];
  admin: string[];
  courses: string[];
  _id: string;
  first_name: string;
  last_name: string;
  business: string;
  student_message: string;
  school_img: string;
  email: string;
}

export interface Student {
  courses: string[];
  _id: string;
  first_name: string;
  last_name: string;
  customer: string;
  group: string;
  email: string;
  grades: [];
  course_progress: any;
}

export interface Course {
  course_title: string;
  customer_id: string;
  reccomended_groups: string[];
  modules: CourseModule[];
  _id?: string;
}

export interface IState {
  sidebarShow: boolean | "" | "responsive" | undefined;
  customers: Customer[] | null;
  user: { role: number | null };
  students: Student[] | null;
  courses: Course[] | null;
  admins: Admin[] | null;
  courseAside: boolean;
}

const initialState: IState = {
  sidebarShow: "responsive",
  customers: null,
  user: { role: null },
  students: null,
  courses: null,
  admins: null,
  courseAside: false,
};

const changeState = (state = initialState, newState: newState) => {
  switch (newState.type) {
    case "set":
      return { ...state, ...newState };
    default:
      return state;
  }
};

const store = createStore(changeState, composeWithDevTools());
export default store;
