import { useContext } from "react";
import { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { CourseContext } from "../../../../../CourseContext";

require("dotenv").config();

export interface TextContentProps {
  slot: string;
}

export const TextContent: React.FC<TextContentProps> = ({ slot }) => {
  let { course, setCourse, module } = useContext(CourseContext);
  const [layoutContent, setLayoutContent] = useState("");

  const handleEditorChange = (content: any) => {
    setLayoutContent(content);

    module = {
      ...module,
      [slot]: {
        ...module[slot],
        content: content,
      },
    };

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );
    course.modules.splice(indexOfModule, 1, module);

    setCourse(course);
  };

  useEffect(() => {
    setLayoutContent(module[slot].content);
  }, [module, slot]);

  return (
    <div className="w-100 h-100">
      <Editor
        value={layoutContent}
        apiKey={process.env.REACT_APP_TINY_KEY}
        init={{
          width: "100%",
          height: "100%",
          resize: false,
          menubar: "file edit view format",
          plugins: [
            "advlist autolink lists link image",
            "charmap print preview anchor help",
            "searchreplace visualblocks code",
            "insertdatetime media table paste wordcount",
          ],
          toolbar:
            // eslint-disable-next-line no-multi-str
            "undo redo | formatselect | bold italic | \
                    alignleft aligncenter alignright | \
                    bullist numlist outdent indent | help",
        }}
        onEditorChange={handleEditorChange}
      />
    </div>
  );
};
