import { useState, useEffect, useContext } from "react";
import QuizMultipleChoice from "./QuizMultipleChoice";
import { CourseContext } from "../../CourseContext";

export default function TemplateQuiz() {
  const { module, displayId } = useContext(CourseContext);
  const [quiz, setQuiz] = useState([]);

  useEffect(() => {
    setQuiz(module.quizContent);
  }, [module]);

  return (
    <div
      className="d-flex flex-column"
      style={{
        backgroundColor: "#5AB3F7",
        height: "100%",
        width: "100%",
        borderRadius: "20px",
        overflow: "auto",
      }}
    >
      {quiz.map(
        (question: any) =>
          displayId === question.id && (
            <div
              key={question.id}
              className="flex-column align-items-end w-100"
              style={{
                backgroundColor: "#0278CF",
              }}
            >
              <div className="h-100 w-100">
                <QuizMultipleChoice question={question} />
              </div>
            </div>
          )
      )}
    </div>
  );
}
