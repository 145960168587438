import { FC } from "react";
import { useEffect, useContext } from "react";
import { TakeCourseContext } from "../../../../../../TakeCourseContext";
import { hasWatchedAllVideosOfModule } from "../../../helpers/hasWatchedAllVideosOfmodule";

export interface VideoContentProps {
  content: string;
}

export const VideoContent: FC<VideoContentProps> = ({ content }) => {
  const {
    setCourseProgress,
    module_id,
    selectedModule,
    setAllowedNext,
    saveProgress,
  } = useContext(TakeCourseContext);

  const checkVideoPlay = () => {
    let video: any = document.getElementById(content);

    let timeStarted = -1;
    let timePlayed = 0;
    let duration = 0;

    const getDuration = () => {
      duration = video.duration;
      //@ts-ignore
      document
        .getElementById(`${content}-duration`)
        ?.appendChild(new Text(Math.round(duration + 1) + ""));
    };

    // If video metadata is laoded get duration
    if (video?.readyState > 0) {
      getDuration.call(video);
    } else {
      //If metadata not loaded, use event to get it
      video?.addEventListener("loadedmetadata", getDuration);
    }
    // remember time user started the video
    const videoStartedPlaying = () => {
      timeStarted = new Date().getTime() / 1000;
    };
    const videoStoppedPlaying = (event: any) => {
      // Start time less then zero means stop event was fired vidout start event
      if (timeStarted > 0) {
        var playedFor = new Date().getTime() / 1000 - timeStarted;
        timeStarted = -1;
        // add the new ammount of seconds played
        timePlayed += playedFor;
      }

      console.log(timePlayed, duration);
      if (timePlayed + 1 >= duration && event.type === "ended") {
        setCourseProgress((cp: any) => {
          const newCourseProgress = {
            ...cp,
            course_progress: {
              ...cp.course_progress,
              [module_id]: {
                ...cp.course_progress?.[module_id],
                watched: {
                  ...cp.course_progress?.[module_id].watched,
                  [content]: Math.round(timePlayed),
                },
              },
            },
          };
          setAllowedNext(
            hasWatchedAllVideosOfModule(
              selectedModule,
              newCourseProgress,
              module_id
            )
          );
          saveProgress(newCourseProgress);
          return newCourseProgress;
        });
      }
    };

    video?.addEventListener("play", videoStartedPlaying);
    video?.addEventListener("playing", videoStartedPlaying);

    video?.addEventListener("ended", videoStoppedPlaying);
    video?.addEventListener("pause", videoStoppedPlaying);
  };

  useEffect(() => {
    checkVideoPlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <div
      className="d-flex w-100 h-100 flex-column justify-content-center align-items-center m-2"
      style={{
        color: "white",
      }}
    >
      <video
        id={content}
        src={content}
        height="100%"
        width="100%"
        style={{ borderRadius: "5px" }}
        controls
      />
    </div>
  );
};
