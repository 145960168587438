import { useState, useEffect, useCallback, useRef } from "react";
import { Get_API } from "../../../API/Get_API";
import { Delete_API } from "../../../API/Delete_API";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import {
  CDataTable,
  CInputCheckbox,
  CContainer,
  CCollapse,
  CCardBody,
  CButton,
  CBadge,
  CTooltip,
} from "@coreui/react";
import { IState, Course } from "../../../store";
import styled from "styled-components";
import { Field, Formik, FormikProps } from "formik";
import ConfirmModal from "../../../components/ConfirmModal";
import {
  StyledSearchContainer,
  FullWidthSearch,
} from "../Customers/CustomersList";
import Loading from "../../../components/Loading";
import { Post_API } from "../../../API/Post_API";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";

const CoursesList: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [query, setQuery] = useState("");
  const [viewing, setViewing] = useState<Course[]>([]);
  const dispatch = useDispatch();
  const courses = useSelector((state: IState) => state.courses);
  const customers = useSelector((state: IState) => state.customers);
  const [details, setDetails] = useState<Array<number>>([]);
  const formRef = useRef<FormikProps<any>>(null);
  const history = useHistory();

  const getCourses = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const courses = await Get_API(`/api/courses`, token);
    if (courses.success) {
      dispatch({ type: "set", courses: courses.success });
    }
  }, [dispatch, getAccessTokenSilently]);

  const getCustomers = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const customers = await Get_API(`/api/customers`, token);
    if (customers.success) {
      dispatch({ type: "set", customers: customers.success });
    }
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields = [
    "course_title",
    { key: "customer_id", label: "Customer" },
    { key: "reccomended_groups", label: "Assigned" },
    "modules",
    {
      key: "show_details",
      label: "Details",
      sorter: false,
      filter: false,
    },
    {
      key: "select",
      label: (
        <span
          onClick={() => {
            if (
              formRef.current?.values.selected_courses.length !==
              viewing?.length
            ) {
              let ids = viewing.map((c) => {
                return c._id;
              });
              formRef.current!.setFieldValue("selected_courses", ids);
            } else {
              formRef.current!.setFieldValue("selected_courses", []);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          Select
        </span>
      ),
    },
  ];

  const toggleDetails = (index: number) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const handleDelete = async (coursesToDelete: any, form: any) => {
    let token = await getAccessTokenSilently();
    let deleteCourses = await Delete_API(
      "/api/courses",
      { coursesToDelete },
      token
    );
    if (deleteCourses.success) {
      form.resetForm();
      getCourses();
    }
  };

  const postCourse = async () => {
    const token = await getAccessTokenSilently();
    const newCourse = await Post_API("/api/courses", {}, token);
    if (newCourse.success) {
      history.push(`/admin/courses/${newCourse.success._id}`);
      if (courses) {
        dispatch({ type: "set", courses: [...courses, newCourse.success] });
      } else {
        dispatch({ type: "set", courses: [newCourse.success] });
      }
    }
  };

  if (!customers) {
    getCustomers();
    return <Loading />;
  }

  return (
    <>
      <PageTitle>Course Library</PageTitle>
      <StyledSearchContainer>
        <FullWidthSearch onChange={(e: any) => setQuery(e.target.value)} />
      </StyledSearchContainer>
      <Formik
        onSubmit={(values, form) => {
          handleDelete(values, form);
        }}
        initialValues={{ selected_courses: [] }}
        innerRef={formRef}
      >
        {(form: FormikProps<any>) => (
          <>
            <CContainer className="table_container">
              <CDataTable
                items={courses ? courses : []}
                fields={fields}
                hover
                sorter
                clickableRows
                pagination
                itemsPerPage={6}
                tableFilterValue={query}
                loading={!courses}
                onFilteredItemsChange={(items: Course[]) => setViewing(items)}
                scopedSlots={{
                  course_title: (course: Course) => (
                    <td
                      onClick={() =>
                        history.push(`/admin/courses/${course._id}`)
                      }
                    >
                      {course.course_title}
                    </td>
                  ),
                  customer_id: (course: Course) => (
                    <td
                      onClick={() =>
                        history.push(`/admin/courses/${course._id}`)
                      }
                    >
                      {
                        customers?.find(
                          (cust) => cust._id === course.customer_id
                        )?.business
                      }
                    </td>
                  ),
                  reccomended_groups: (course: Course) => (
                    <td
                      onClick={() =>
                        history.push(`/admin/courses/${course._id}`)
                      }
                    >
                      {course.reccomended_groups.length}
                    </td>
                  ),
                  modules: (course: Course) => (
                    <td
                      onClick={() =>
                        history.push(`/admin/courses/${course._id}`)
                      }
                    >
                      {course.modules.length}
                    </td>
                  ),
                  show_details: (course: Course, index: number) => {
                    return (
                      <td className="py-2">
                        <CButton
                          color="primary"
                          variant="outline"
                          shape="square"
                          size="sm"
                          onClick={() => {
                            toggleDetails(index);
                          }}
                        >
                          {details.includes(index) ? "Hide" : "Show"}
                        </CButton>
                      </td>
                    );
                  },
                  details: (course: Course, index: number) => {
                    return (
                      <CCollapse show={details.includes(index)}>
                        <CCardBody
                          color="primary-light"
                          onClick={() =>
                            history.push(`/admin/courses/${course._id}`)
                          }
                        >
                          <h4>Assigned Groups</h4>
                          {course.reccomended_groups.length > 0 ? (
                            course.reccomended_groups.map((group) => (
                              <CBadge
                                key={group}
                                color="success"
                                className="m-2"
                              >
                                {group}
                              </CBadge>
                            ))
                          ) : (
                            <h5>No Groups</h5>
                          )}
                        </CCardBody>
                      </CCollapse>
                    );
                  },
                  select: (course: Course) => (
                    <td className="d-flex justify-content-center">
                      <Field
                        name="selected_courses"
                        checked={form.values.selected_courses.includes(
                          course._id
                        )}
                        value={course._id}
                        as={CInputCheckbox}
                      />
                    </td>
                  ),
                }}
              />
            </CContainer>
            <CContainer className="d-flex justify-content-between p-0">
              <ConfirmModal
                title="Delete Course(s)"
                buttonText={`Delete`}
                icon="cil-trash"
                query={`Are you sure you want to delete the ${formRef.current?.values.selected_courses.length} course(s)?`}
                buttonColor="danger"
                onConfirm={form.handleSubmit}
                buttonClassname="mr-3"
                disabled={formRef.current?.values.selected_courses.length === 0}
              />
              <div className="d-flex">
                <CTooltip content="New Course">
                  <CButton color="success" onClick={postCourse}>
                    <CIcon name="cil-plus" />
                  </CButton>
                </CTooltip>
              </div>
            </CContainer>
          </>
        )}
      </Formik>
    </>
  );
};

const PageTitle = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 3em;
`;

export default CoursesList;
