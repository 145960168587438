import { CourseModule } from "../../../../../Admin/Courses/Course/Details/CourseModules";

export const hasWatchedAllVideosOfModule = (
  selectedModule: CourseModule,
  courseProgress: any,
  module_id: string
) => {
  const layout = selectedModule?.moduleLayout;

  const vidsWatched = Object.keys(
    courseProgress?.course_progress?.[module_id]?.watched ?? {}
  ).length;

  if (layout === "layout-vid") {
    if (
      selectedModule.moduleContent1.type !== "video" ||
      selectedModule.moduleContent1 === "textAndPicture"
    ) {
      return true;
    } else {
      if (vidsWatched === 1) {
        return true;
      } else {
        return false;
      }
    }
  } else if (layout === "layout1" || layout === "layout2") {
    if (
      selectedModule.moduleContent1.type !== "video" &&
      selectedModule.moduleContent2.type !== "video"
    ) {
      return true;
    } else if (
      selectedModule.moduleContent1.type === "video" &&
      selectedModule.moduleContent2.type === "video"
    ) {
      if (vidsWatched === 2) {
        return true;
      } else {
        return false;
      }
    } else if (
      (selectedModule.moduleContent1.type === "video" &&
        selectedModule.moduleContent2.type !== "video") ||
      (selectedModule.moduleContent1.type !== "video" &&
        selectedModule.moduleContent2.type === "video")
    ) {
      if (vidsWatched === 1) {
        return true;
      } else {
        return false;
      }
    }
  } else if (layout === "layout3") {
    if (
      selectedModule.moduleContent1.type !== "video" &&
      selectedModule.moduleContent2.type !== "video" &&
      selectedModule.moduleContent3.type !== "video"
    ) {
      return true;
    } else if (
      selectedModule.moduleContent1.type === "video" &&
      selectedModule.moduleContent2.type === "video" &&
      selectedModule.moduleContent3.type === "video"
    ) {
      if (vidsWatched === 3) {
        return true;
      } else {
        return false;
      }
    } else if (
      (selectedModule.moduleContent1.type === "video" &&
        selectedModule.moduleContent2.type !== "video" &&
        selectedModule.moduleContent3.type !== "video") ||
      (selectedModule.moduleContent1.type !== "video" &&
        selectedModule.moduleContent2.type === "video" &&
        selectedModule.moduleContent3.type !== "video") ||
      (selectedModule.moduleContent1.type !== "video" &&
        selectedModule.moduleContent2.type !== "video" &&
        selectedModule.moduleContent3.type === "video")
    ) {
      if (vidsWatched === 1) {
        return true;
      } else {
        return false;
      }
    } else if (
      (selectedModule.moduleContent1.type === "video" &&
        selectedModule.moduleContent2.type === "video" &&
        selectedModule.moduleContent3.type !== "video") ||
      (selectedModule.moduleContent1.type !== "video" &&
        selectedModule.moduleContent2.type === "video" &&
        selectedModule.moduleContent3.type === "video") ||
      (selectedModule.moduleContent1.type === "video" &&
        selectedModule.moduleContent2.type !== "video" &&
        selectedModule.moduleContent3.type === "video")
    ) {
      if (vidsWatched === 2) {
        return true;
      } else {
        return false;
      }
    }
  }
  return true;
};
