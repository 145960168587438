import React, { useState } from "react";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CTooltip,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

export interface ConfirmModalProps {
  buttonColor: "danger" | "primary" | "secondary" | "warning";
  buttonText: string;
  disabled?: boolean;
  title: string;
  query: string;
  onConfirm: () => void;
  buttonClassname?: string;
  icon?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  buttonColor,
  buttonClassname,
  buttonText,
  disabled,
  title,
  query,
  onConfirm,
  icon,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <CTooltip content={title}>
        <CButton
          className={buttonClassname}
          color={buttonColor}
          onClick={toggle}
          disabled={disabled}
        >
          {icon ? <CIcon name={icon} /> : buttonText}
        </CButton>
      </CTooltip>
      <CModal show={modal}>
        <CModalHeader>
          <span className="modal-title">{title}</span>
        </CModalHeader>
        <CModalBody>
          <p>{query}</p>
        </CModalBody>
        <CModalFooter>
          <CButton
            size="md"
            color="success"
            onClick={() => {
              onConfirm();
              toggle();
            }}
          >
            Confirm
          </CButton>
          <CButton size="md" color="danger" onClick={toggle}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default ConfirmModal;
