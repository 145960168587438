import IframeResizer from "iframe-resizer-react";
import { useState, useEffect, useContext, useCallback } from "react";
import CIcon from "@coreui/icons-react";
import { CourseContext } from "../../../../../CourseContext";
import { MediaModal } from "../../../../../../../../../components/media-upload";
import { Get_API } from "../../../../../../../../../API/Get_API";
import { useAuth0 } from "@auth0/auth0-react";
import { Post_API } from "../../../../../../../../../API/Post_API";
import { CSpinner } from "@coreui/react";

export interface IspringHTMLCreateContentProps {
  slot: string;
}

export const IspringHTMLCreateContent: React.FC<IspringHTMLCreateContentProps> =
  ({ slot }) => {
    let { course, setCourse, module } = useContext(CourseContext);
    const [hovering, setHovering] = useState(false);
    const [ispringMedia, setIspringMedia] = useState([]);
    const [ispringLink, setIspringLink] = useState<string | null>(null);
    const { getAccessTokenSilently } = useAuth0();
    const [isExtracted, setIsExtracted] = useState(false);

    useEffect(() => {
      setIspringLink(module[slot].content);
    }, [module, slot]);

    const getAllIspringMedia = useCallback(async () => {
      try {
        const token = await getAccessTokenSilently();
        const images = await Get_API("/api/media?type=ispring-html", token);
        if (images.success) {
          setIspringMedia(images.success);
        }
      } catch (error) {
        console.log(error);
      }
    }, [getAccessTokenSilently]);

    useEffect(() => {
      getAllIspringMedia();
    }, [getAccessTokenSilently, getAllIspringMedia]);

    const saveIspringMedia = async (fileName: string) => {
      let newMedia = {
        type: "ispring-html",
        link: fileName,
        title: fileName,
      };

      try {
        const token = await getAccessTokenSilently();
        await Post_API("/api/media", newMedia, token);
        getAllIspringMedia();
      } catch (error) {
        console.log(error);
      }
    };

    const uploadFileTos3 = (file: any, signedRequest: any, url: string) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            saveIspringMedia(file.fileName);
            saveIspringLinkToModule(file.fileName);
          } else {
            alert("Could not upload file.");
          }
        }
      };
      xhr.send(file.file);
    };

    const getSignedRequest = async (file: any) => {
      const token = await getAccessTokenSilently();

      //Format Name
      const fileName = `${file.fileName
        .split(".")[0]
        .toLowerCase()}-${Date.now()}`;

      file.fileName = fileName;

      const signedRequest = await Get_API(
        `/api/s3Bucket/sign-s3?file-name=${fileName}.zip&file-type=${file.file.type}`,
        token
      );
      if (signedRequest.success) {
        return signedRequest.success;
      } else {
        window.alert("Failed to approve request to upload file to s3 bucket.");
        return false;
      }
    };

    const handleUpload = async (file: File) => {
      const signedRequest = await getSignedRequest(file);
      if (signedRequest) {
        uploadFileTos3(file, signedRequest.signedRequest, signedRequest.url);
      }
    };

    const saveIspringLinkToModule = (fileName: string) => {
      module = {
        ...module,
        [slot]: {
          ...module[slot],
          content: fileName,
        },
      };

      const indexOfModule = course.modules.findIndex(
        (mod: any) => mod.id === module.id
      );
      course.modules.splice(indexOfModule, 1, module);

      setCourse(course);
    };

    const handleRemoveFromModule = () => {
      module = {
        ...module,
        [slot]: "",
      };

      const indexOfModule = course.modules.findIndex(
        (mod: any) => mod.id === module.id
      );
      course.modules.splice(indexOfModule, 1, module);

      setIspringLink(null);

      setCourse(course);
    };

    useEffect(() => {
      const checkExtracted = async () => {
        const checkIspring = await fetch(
          `${process.env.REACT_APP_S3_ROOT_URL}${ispringLink}/index.html`
        );

        console.log(checkIspring);
        if (checkIspring.ok) {
          setIsExtracted(true);
        } else {
          setTimeout(checkExtracted, 2000);
        }
      };

      if (ispringLink) {
        checkExtracted();
      }
    }, [ispringLink]);

    if (!isExtracted && ispringLink) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100">
          <CSpinner />
        </div>
      );
    }

    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{
          color: "white",
          position: "relative",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {module[slot].content ? (
          <>
            <IframeResizer
              frameBorder="0"
              width="100%"
              height="100%"
              src={`${process.env.REACT_APP_S3_ROOT_URL}${module[slot].content}/index.html`}
              title="Ispring Course"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                borderRadius: "3px",
                backgroundColor: "transparent",
              }}
            />
            <CIcon
              name="cil-trash"
              color="red"
              size="2xl"
              style={{
                color: "red",
                position: "absolute",
                cursor: "pointer",
                opacity: hovering ? "1" : "0.3",
                zIndex: 1000,
              }}
              onMouseEnter={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}
              onClick={handleRemoveFromModule}
            />
          </>
        ) : (
          <MediaModal
            handleMediaSelect={(link) => {
              saveIspringLinkToModule(link);
            }}
            handleUpload={(v) => handleUpload(v)}
            media={ispringMedia}
            useTrigger={!!ispringLink}
            fileType="application/zip"
          />
        )}
      </div>
    );
  };
