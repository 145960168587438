import React, { FC } from "react";

export interface YoutubeVideoLinkContentProps {
  content: string;
}

export const YoutubeVideoLinkContent: FC<YoutubeVideoLinkContentProps> = ({
  content,
}) => {
  return (
    <div
      className="d-flex w-100 h-100 flex-column justify-content-center align-items-center m-2"
      style={{
        color: "white",
      }}
    >
      <iframe
        width="100%"
        height="315"
        style={{ borderRadius: "3px" }}
        src={`https://www.youtube.com/embed/${content}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  );
};
