import { useState, useEffect } from "react";
import { CDataTable, CInput, CContainer } from "@coreui/react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import { Get_API } from "../../API/Get_API";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Customer, Student } from "../../store";

export default function MyCourses() {
  const [query, setQuery] = useState("");
  const [courseProgresses, setCourseProgresses] = useState([]);
  const history = useHistory();
  const [student, setStudent] = useState<Student | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    const getCourseProgress = async () => {
      const token = await getAccessTokenSilently();
      const progressRequest = await Get_API(
        `/api/course-progress?user_email=${user?.name}`,
        token
      );
      if (progressRequest.success) {
        setCourseProgresses(progressRequest.success);
      }
    };
    getCourseProgress();
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    const getStudent = async () => {
      const token = await getAccessTokenSilently();
      const studentRequest = await Get_API(
        `/api/students?student_email=${user?.name}`,
        token
      );
      if (studentRequest.success[0]) {
        setStudent(studentRequest.success[0]);
      } else {
        setStudent(null);
      }
    };
    getStudent();
  }, [getAccessTokenSilently, user]);

  useEffect(() => {
    if (!customer) {
      const getCustomer = async () => {
        const token = await getAccessTokenSilently();
        const customer = await Get_API(
          `/api/customers?customer_id=${student?.customer}`,
          token
        );
        if (customer.success[0]) {
          setCustomer(customer.success[0]);
        }
      };
      if (student?.customer) {
        getCustomer();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student, getAccessTokenSilently()]);

  const fields = ["course_title", "finalGrade", "dateAssigned"];

  return (
    <>
      <PageTitle>My Courses</PageTitle>
      <span className="text-light">
        {`for 
          ${
            student?.first_name
              ? student?.first_name[0].toUpperCase() +
                student?.first_name.slice(1, student?.first_name.length)
              : "TEST STUDENT"
          } 
          ${
            student?.last_name
              ? student?.last_name[0].toUpperCase() +
                student?.last_name.slice(1, student?.last_name.length)
              : undefined
          } at ${customer?.business ?? "TEST BUSINESS"}.`}
      </span>
      <StyledSearchContainer>
        <FullWidthSearch onChange={(e: any) => setQuery(e.target.value)} />
      </StyledSearchContainer>
      <CContainer className="table_container">
        <CDataTable
          items={courseProgresses ? courseProgresses : []}
          fields={fields}
          hover
          sorter
          clickableRows
          onRowClick={(courseSelected: any) =>
            history.push(`/course/${courseSelected.course_id}`)
          }
          pagination
          itemsPerPage={6}
          tableFilterValue={query}
          loading={!courseProgresses}
          scopedSlots={{
            course_title: (courseProgress: any) => (
              <td>
                {courseProgress.course_title
                  ? courseProgress.course_title
                  : "No Title"}
              </td>
            ),
            finalGrade: (courseProgress: any) => (
              <td>
                {courseProgress.finalGrade
                  ? `${(courseProgress.finalGrade * 100).toFixed()}%`
                  : "Not Complete"}
              </td>
            ),
            dateAssigned: (courseProgress: any) => (
              <td>
                {moment(courseProgress.dateAssigned)
                  .parseZone()
                  .format("MM/DD/YYYY")}
              </td>
            ),
          }}
        />
      </CContainer>
    </>
  );
}

export const StyledSearchContainer = styled(CContainer)`
  padding: 0;
`;

export const FullWidthSearch = styled(CInput)`
  width: 100%;
  margin: 1rem auto;
`;

const PageTitle = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 3em;
`;
