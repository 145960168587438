import { useContext } from "react";
import { TakeCourseContext } from "../../../TakeCourseContext";
import { TemplateContent } from "./template-content";

export default function Template2() {
  const { selectedModule, setAllowedNext } = useContext(TakeCourseContext);

  return (
    <div
      className="d-flex"
      style={{
        background: "linear-gradient(0.375turn, #1b2c5f, #0562A5)",
        height: "100%",
        width: "100%",
        borderRadius: "10px",
        overflow: "auto",
        minHeight: "400px",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center w-50 h-100 m-3"
        style={{
          borderRadius: "3px",
          alignSelf: "center",
          maxWidth: "50%",
        }}
      >
        <TemplateContent
          moduleContent={selectedModule?.moduleContent1}
          setAllowedNext={setAllowedNext}
        />
      </div>
      <div
        className="d-flex flex-column w-50 m-3"
        style={{
          alignSelf: "center",
          maxWidth: "50%",
        }}
      >
        <div
          className="d-flex flex-column align-items-center justify-content-center w-100"
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "3px",
            alignSelf: "center",
          }}
        >
          <TemplateContent
            moduleContent={selectedModule?.moduleContent2}
            setAllowedNext={setAllowedNext}
          />
        </div>
      </div>
    </div>
  );
}
