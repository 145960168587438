import { useCallback, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TheLayout from "./containers/TheLayout";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import "./App.css";
import "./scss/styles.scss";
import Loading from "./components/Loading";
import { Get_API } from "./API/Get_API";
import { InvalidUser } from "./pages/Messages/InvalidUser";

function App() {
  const {
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    user,
  } = useAuth0();
  const dispatch = useDispatch();
  const [invalidUser, setInvalidUser] = useState<boolean>(false);
  const [roleFetched, setRoleFetched] = useState(false);

  const findRole = useCallback(async () => {
    try {
      if (user?.sub === process.env.REACT_APP_ADMIN_ID) {
        dispatch({
          type: "set",
          user: { ...user, role: 1 },
        });
        setRoleFetched(true);
      } else {
        const token = await getAccessTokenSilently();
        const encodedEmail = encodeURIComponent(user?.name ?? "");
        const role = await Get_API(
          `/api/role?user_email=${encodedEmail}`,
          token
        );
        setRoleFetched(true);
        if (role.success < 4) {
          dispatch({
            type: "set",
            user: { ...user, role: role.success, _id: role._id },
          });
          setInvalidUser(false);
        } else {
          setInvalidUser(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [getAccessTokenSilently, user, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { target: window.location.pathname } });
    return null;
  } else if (isAuthenticated && !roleFetched) {
    findRole();
  }

  if (invalidUser && roleFetched) {
    return <InvalidUser />;
  }

  if (!roleFetched) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/">
            <TheLayout />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
