import React, { useContext } from "react";
import { CButton, CImg } from "@coreui/react";
import { TakeCourseContext } from "../TakeCourseContext";
import { CourseModule } from "../../Admin/Courses/Course/Details/CourseModules";

const Welcome: React.FC = () => {
  const { course, nextModule, currentPanel, enableButtons, customer } =
    useContext(TakeCourseContext);
  return (
    <div
      className="d-flex flex-column h-100 align-items-start justify-content-center"
      style={{
        zIndex: 10,
      }}
    >
      <div className="d-flex flex-column h-100 w-100" style={{}}>
        <div
          className="d-flex flex-column text-center align-items-center justify-content-center"
          style={{
            background: "linear-gradient(0.375turn, #1b2c5f, #0562A5)",
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            overflow: "auto",
            flexWrap: "wrap",
          }}
        >
          <CImg
            src={
              customer?.school_img && customer?.school_img !== ""
                ? customer?.school_img
                : `${process.env.PUBLIC_URL}/SchoolPlaceholder.png`
            }
            className="p-3"
            style={{
              maxWidth: "80%",
              maxHeight: "400px",
              alignSelf: "center",
            }}
          />
          <p
            className="m-2 text-light"
            style={{
              fontSize: "1.25rem",
            }}
          >
            {customer?.student_message ??
              "This course is marked as a template."}
          </p>
        </div>
        <CButton
          id="welcomeStartCourse"
          onClick={() => {
            enableButtons();
            nextModule();
          }}
          color="success"
          size="md"
          alt="next module"
          className="my-3"
          style={{
            width: "100%",
            display: currentPanel === "Welcome" ? "block" : "none",
          }}
          disabled={
            course?.modules.findIndex(
              (mod: CourseModule) => mod.id === currentPanel
            ) ===
            (course?.modules.length ?? 0) - 1
          }
        >
          Start Course
        </CButton>
      </div>
    </div>
  );
};

export default Welcome;
