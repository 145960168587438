import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Welcome from "./Details/Welcome";
import CourseContent from "./Details/CourseContent/CourseContent";
import { Customer, Student, Course, IState } from "../../store";
import { CourseModule } from "../Admin/Courses/Course/Details/CourseModules";
import { Get_API } from "../../API/Get_API";
import { Patch_API } from "../../API/Patch_API";
import { TakeCourseContext } from "./TakeCourseContext";
import CourseNavigationAside from "./Details/CourseContent/CourseNavAside";
import CourseNavigationBottom from "./Details/CourseNavigationBottom";
import { CButton } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";

export interface CourseParams {
  course_id: string;
  module_id?: string;
}

const CourseDash: React.FC = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [course, setCourse] = useState<Course | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [student, setStudent] = useState<Student | null>(null);
  const [courseProgress, setCourseProgress] = useState<any>({});
  const history = useHistory();
  const [allowedNext, setAllowedNext] = useState(true);
  const { course_id, module_id } = useParams<CourseParams>();
  const selectedModule = course?.modules.find(
    (mod: CourseModule) => mod.id === module_id
  );

  const dispatch = useDispatch();
  const courseAside = useSelector((state: IState) => state.courseAside);

  useEffect(() => {
    if (!customer) {
      const getCustomer = async () => {
        const token = await getAccessTokenSilently();
        const customer = await Get_API(
          `/api/customers?customer_id=${course?.customer_id}`,
          token
        );
        if (customer.success[0]) {
          setCustomer(customer.success[0]);
        }
      };
      if (course?.customer_id) {
        getCustomer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, getAccessTokenSilently()]);

  useEffect(() => {
    const getCourse = async (_id: string) => {
      const token = await getAccessTokenSilently();
      const courseRequest = await Get_API(
        `/api/courses?course_id=${_id}`,
        token
      );
      if (courseRequest.success) {
        setCourse(courseRequest.success[0]);
      }
    };

    if (course_id) {
      getCourse(course_id);
    }
  }, [course_id, getAccessTokenSilently, user?.name]);

  useEffect(() => {
    const getStudent = async () => {
      const token = await getAccessTokenSilently();
      const studentRequest = await Get_API(
        `/api/students?student_email=${user?.name}`,
        token
      );
      if (studentRequest.success[0]) {
        setStudent(studentRequest.success[0]);
      } else {
        setStudent(null);
      }
    };
    getStudent();
  }, [getAccessTokenSilently, user, course_id]);

  useEffect(() => {
    const getCourseProgress = async () => {
      const token = await getAccessTokenSilently();
      const progressRequest = await Get_API(
        `/api/course-progress?user_email=${user?.name}&course_id=${course_id}`,
        token
      );
      if (progressRequest.success) {
        setCourseProgress(progressRequest.success);
      }
    };
    getCourseProgress();
  }, [getAccessTokenSilently, user, course_id]);

  const nextModule = () => {
    let indexOfCurrentModule =
      course?.modules.findIndex((mod: CourseModule) => mod.id === module_id) ??
      -1;
    if (indexOfCurrentModule !== course?.modules.length! - 1) {
      const nextModuleId = course?.modules[indexOfCurrentModule + 1].id;
      history.push(`/course/${course_id}/${nextModuleId}`);
      updateProgress(nextModuleId);
    }
  };

  const prevModule = () => {
    let indexOfCurrentModule =
      course?.modules.findIndex((mod: CourseModule) => mod.id === module_id) ??
      -1;
    if (indexOfCurrentModule !== 0) {
      const prevModuleId = course?.modules[indexOfCurrentModule - 1].id;
      history.push(`/course/${course_id}/${prevModuleId}`);
      updateProgress(prevModuleId);
    } else {
      history.push(`/course/${course_id}`);
    }
  };

  const updateProgress = (moduleId: any) => {
    if (courseProgress.course_progress[moduleId]) {
      saveProgress(courseProgress);
      return;
    } else {
      setCourseProgress((currProgress: any) => {
        let progress = {
          ...currProgress,
          course_progress: { ...currProgress.course_progress, [moduleId]: {} },
        };
        saveProgress(progress);
        return progress;
      });
    }
  };

  const saveProgress = useCallback(
    async (progress: any) => {
      const token = await getAccessTokenSilently();
      await Patch_API(`/api/course-progress`, { ...progress }, token);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseProgress, getAccessTokenSilently]
  );

  const toggleAside = () => {
    dispatch({ type: "set", courseAside: !courseAside });
  };

  if (courseProgress.finalGrade || courseProgress.finalGrade === 0) {
    return <Redirect to={`/course/grade/${course_id}`} />;
  }

  return (
    <TakeCourseContext.Provider
      value={{
        course,
        allowedNext,
        customer,
        setAllowedNext,
        selectedModule,
        module_id,
        course_id,
        nextModule,
        prevModule,
        toggleAside,
        aside: courseAside,
        courseProgress,
        saveProgress,
        updateProgress,
        setCourseProgress,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          zIndex: 10,
        }}
      >
        <div className="d-flex flex-column w-100 h-100 p-3">
          <div className="py-3">
            <h1 className="text-light">Welcome to {course?.course_title}</h1>
            <span className="text-light">
              {`for
              ${
                student?.first_name
                  ? student?.first_name[0].toUpperCase() +
                    student?.first_name.slice(1, student?.first_name.length)
                  : "TEST STUDENT"
              } 
              ${
                student?.last_name
                  ? student?.last_name[0].toUpperCase() +
                    student?.last_name.slice(1, student?.last_name.length)
                  : undefined
              } at ${customer?.business ?? "TEST BUSINESS"}.`}
            </span>
          </div>
          {!module_id && <Welcome />}
          {module_id && <CourseContent />}
          {module_id && <CourseNavigationBottom />}
          <CourseNavigationAside />
          {!module_id && (
            <CButton
              color="success"
              className="my-2"
              onClick={nextModule}
              disabled={!course}
            >
              {course
                ? "Start Course"
                : "This course is not available anymore."}
            </CButton>
          )}
        </div>
      </div>
    </TakeCourseContext.Provider>
  );
};

export default CourseDash;
