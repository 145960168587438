import { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { CourseContext } from "../../../../../CourseContext";
import {
  Media,
  MediaModal,
} from "../../../../../../../../../components/media-upload";
import { Get_API } from "../../../../../../../../../API/Get_API";
import { Post_API } from "../../../../../../../../../API/Post_API";
import { Delete_API } from "../../../../../../../../../API/Delete_API";

export interface VideoContentProps {
  slot: string;
}

export const VideoContent: React.FC<VideoContentProps> = ({ slot }) => {
  let { course, setCourse, module } = useContext(CourseContext);
  const [modal, setModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [videos, setVideos] = useState<Media[]>([]);
  const [hovering, setHovering] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const toggle = () => {
    setModal(!modal);
  };

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal);
  };

  useEffect(() => {
    setSelectedVideo(module[slot].content);
  }, [module, slot]);

  const getAllVideos = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const videos = await Get_API("/api/media?type=video", token);
      if (videos.success) {
        setVideos(videos.success);
      }
    } catch (error) {
      console.log(error);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getAllVideos();
  }, [getAccessTokenSilently, getAllVideos]);

  const handleUpload = (values: { file: any; fileName: string }) => {
    const formData = new FormData();
    formData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET!
    );
    formData.append("file", values.file);

    axios
      .post(process.env.REACT_APP_CLOUDINARY_URI_VIDEO!, formData)
      .then((res) => {
        setVideos((vids: any) => [
          ...vids,
          {
            type: "video",
            link: res.data.secure_url,
          },
        ]);
        saveVideo(res.data.secure_url, values.fileName);
        setSelectedVideo(res.data.secure_url);
        saveVideoToModule(res.data.secure_url);
        toggleUploadModal();
        toggle();
      })
      .catch((err) => console.log(err));
  };

  const saveVideo = async (link: string, title: string) => {
    let newMedia = {
      type: "video",
      link: link,
      title: title,
    };

    try {
      try {
        const token = await getAccessTokenSilently();
        await Post_API("/api/media", newMedia, token);
        getAllVideos();
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveVideoToModule = (link: any) => {
    module = {
      ...module,
      [slot]: {
        ...module[slot],
        content: link,
      },
    };

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );
    course.modules.splice(indexOfModule, 1, module);

    setCourse(course);
  };

  const handleDelete = async (mediaToDelete: any) => {
    let token = await getAccessTokenSilently();
    let deleteVideos = await Delete_API("/api/media", { mediaToDelete }, token);
    if (deleteVideos.success) {
      getAllVideos();
    }
  };

  const handleRemoveFromModule = () => {
    module = {
      ...module,
      [slot]: "",
    };

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );
    course.modules.splice(indexOfModule, 1, module);

    setSelectedVideo(null);

    setCourse(course);
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      {selectedVideo ? (
        <>
          <StyledVideo
            src={selectedVideo}
            width="100%"
            height="100%"
            style={{
              borderRadius: "5px",
              opacity: hovering ? "0.5" : "1",
              zIndex: 500,
            }}
            controls
          />
          <CIcon
            name="cil-trash"
            color="red"
            size="2xl"
            style={{
              color: "red",
              position: "absolute",
              cursor: "pointer",
              opacity: hovering ? "1" : "0.3",
              zIndex: 1000,
            }}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onClick={handleRemoveFromModule}
          />
        </>
      ) : (
        <MediaModal
          useTrigger={selectedVideo ? false : true}
          media={videos}
          handleMediaSelect={saveVideoToModule}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

const StyledVideo = styled.video`
  border-radius: 5px;
  cursor: pointer;
  &::-webkit-media-controls-overlay-play-button {
    display: none;
  }
`;
