import { CHeader, CHeaderBrand, CImg, CToggler } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../store";

const TheHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state: IState) => state.sidebarShow);

  const toggleSidebarMobile = () => {
    console.log(sidebarShow);
    const val = [false, "responsive", undefined].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch({ type: "set", sidebarShow: val });
  };

  return (
    <>
      <CHeader
        colorScheme="dark bg-secondary"
        className="ml-md-3 d-lg-none"
        style={{ borderBottom: "#f87d6f" }}
      >
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={toggleSidebarMobile}
        />
        <CHeaderBrand
          className="position-absolute d-lg-none w-50"
          style={{ right: "25%" }}
          to="/"
        >
          <CImg
            src={`${process.env.PUBLIC_URL}/fullLogoDark2.png`}
            width="60%"
          />
        </CHeaderBrand>
      </CHeader>
    </>
  );
};

export default TheHeader;
