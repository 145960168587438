import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { icons } from "./assets/icons";
import { createBrowserHistory } from "history";

require("dotenv").config();

declare global {
  namespace React {
    let icons: any;
  }
}

React.icons = icons;

const onRedirectCallback = (appState: AppState) => {
  const history = createBrowserHistory();

  // Use the router's history module to replace the url
  history.replace(appState?.target || window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID!}
      redirectUri={window.location.origin}
      audience="http://localhost:5000"
      scope="read:current_user update:current_user_metadata"
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
