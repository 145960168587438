import React, { useCallback, useContext } from "react";
import { CContainer, CSelect } from "@coreui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { Customer, IState } from "../../../../../store";
import { useSelector } from "react-redux";
import Loading from "../../../../../components/Loading";
import { Get_API } from "../../../../../API/Get_API";
import { useDispatch } from "react-redux";
import { CourseContext } from "../CourseContext";

const AssignCustomer: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const customers = useSelector((state: IState) => state.customers);
  const dispatch = useDispatch();
  const { course, setCourse } = useContext(CourseContext);

  const getCustomers = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const customers = await Get_API(`/api/customers`, token);
    if (customers.success) {
      dispatch({ type: "set", customers: customers.success });
    }
  }, [dispatch, getAccessTokenSilently]);

  const addCustomer = (_id: string) => {
    if (_id !== "template") {
      course.customer_id = _id;
      setCourse(course);
    } else if (_id === "template") {
      course.customer_id = "";
      course.reccomended_groups = [];
      setCourse(course);
    }
  };

  if (!customers) {
    getCustomers();
    return <Loading />;
  }

  return (
    <CContainer className="p-0 mr-2">
      <CSelect
        style={{
          backgroundColor: "#0A70B9",
          border: "none",
          color: "white",
        }}
        value={course.customer_id}
        onChange={(e: any) => addCustomer(e.target.value)}
      >
        <option value="template">Use As Template</option>
        {customers.map((cust: Customer) => {
          return (
            <option key={cust._id} value={cust._id}>
              {cust.business}
            </option>
          );
        })}
      </CSelect>
    </CContainer>
  );
};

export default AssignCustomer;
