import React, { useState, useEffect, useRef } from "react";
import {
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModal,
  CButton,
  CTooltip,
} from "@coreui/react";
import { CSVReader } from "react-papaparse";
import { useAuth0 } from "@auth0/auth0-react";
import { Customer, Student } from "../../../store";
import { Post_API } from "../../../API/Post_API";
import CIcon from "@coreui/icons-react";

export interface BulkImportStudentsProps {
  students: Student[] | null;
  customer: Customer;
  getStudents: () => void;
}

export interface NewRosterData {
  data: string[];
  errors: any[];
  meta: any;
}

const BulkImportStudents: React.FC<BulkImportStudentsProps> = ({
  customer,
  students,
  getStudents,
}) => {
  const [modal, setModal] = useState(false);
  const [newRoster, setNewRoster] = useState<NewRosterData[] | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const [uploading, setUploading] = useState(false);
  const [uploadingText, setUploadingText] = useState("Uploading...");
  const buttonRef = useRef<CSVReader>(null);

  const toggle = () => {
    if (uploading) {
      window.alert("Uploading in progress... Please wait.");
    } else {
      setModal(!modal);
    }
  };

  useEffect(() => {
    uploadingText.length === 12 &&
      setTimeout(() => {
        setUploadingText("Uploading");
      }, 500);
    uploadingText.length === 9 &&
      setTimeout(() => {
        setUploadingText("Uploading.");
      }, 500);
    uploadingText.length === 10 &&
      setTimeout(() => {
        setUploadingText("Uploading..");
      }, 500);
    uploadingText.length === 11 &&
      setTimeout(() => {
        setUploadingText("Uploading...");
      }, 500);
  }, [uploadingText]);

  useEffect(() => {
    if (uploading) {
      setUploadingText("Uploading...");
    } else {
      setUploadingText("");
    }
  }, [uploading]);

  const handleOpenDialog = (e: any) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data: any) => {
    setNewRoster(data);
  };

  const handleOnError = (err: any) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data: any) => {
    console.log(data);
  };
  const handleRemoveFile = (e: any) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile();
    }
  };

  const addStudents = async () => {
    setUploading(true);
    const token = await getAccessTokenSilently();
    let allNewStudents = [];
    let currentStudentEmails = students
      ? students.map((stud) => {
          return stud.email;
        })
      : [];
    let studentsNotImported = [];
    if (newRoster) {
      for (let i = 1; i < newRoster!.length; i++) {
        let assignedCourses;
        try {
          const token = await getAccessTokenSilently();
          const resposne = await fetch(
            `/api/getCoursesByGroup/${newRoster![i].data[2]}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json; Charset=UTF-8",
              },
            }
          );
          const responseData = await resposne.json();
          assignedCourses = responseData;
        } catch (error) {
          console.log(error);
        }
        console.log(assignedCourses);
        var newStudentTemplateBulkImport: any = {
          first_name: newRoster[i].data[0],
          last_name: newRoster[i].data[1],
          email: newRoster[i].data[2],
          group: newRoster[i].data[3],
          role: "Student",
          business: customer.business,
          customer: customer._id,
          courses: assignedCourses,
        };
        if (!currentStudentEmails.includes(newRoster[i].data[1])) {
          if (
            newRoster[i].data[0] &&
            newRoster[i].data[1] &&
            newRoster[i].data[2]
          ) {
            try {
              const newStudent = await Post_API(
                "/api/students",
                newStudentTemplateBulkImport,
                token
              );
              newStudentTemplateBulkImport._id = newStudent._id;
              allNewStudents.push(newStudentTemplateBulkImport);
            } catch (error) {
              console.log(error);
            }
          }
          if (i === newRoster.length - 1) {
            window.alert("Upload Complete!");
            setUploading(false);
            setNewRoster(null);
            toggle();
          }
        } else {
          studentsNotImported.push(newStudentTemplateBulkImport);
        }
      }
      getStudents();
    }
    setUploading(false);
    setUploadingText("");
    studentsNotImported.length > 0 &&
      window.alert(
        `Uh Oh! ${
          studentsNotImported.length
        } students were already part of this roster including: ${studentsNotImported.map(
          (stud) => {
            return ` ${stud.name}`;
          }
        )}. They will not be re-uploaded.`
      );
  };

  return (
    <div>
      <CTooltip content="Import Students">
        <CButton
          onClick={toggle}
          color="primary"
          size="md"
          alt="bulk student import"
          className="ml-2"
        >
          <CIcon name="cil-cloud-upload" />
        </CButton>
      </CTooltip>
      <CModal show={modal}>
        <CModalHeader>
          <span className="modalTitle">Bulk Add Students</span>
        </CModalHeader>
        <CModalBody className="d-flex flex-row w-100 justify-content-around align-items-center">
          <div className="d-flex flex-column justify-content-around align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center my-2">
              <CButton
                size="md"
                alt="download template"
                className="w-100"
                href={`${process.env.PUBLIC_URL}/StudentImport.csv`}
                color="primary"
              >
                Download Template
              </CButton>
            </div>
            <div className="d-flex flex-row w-100 justify-content-center align-items-center my-2">
              <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                noClick
                noDrag
                onRemoveFile={handleOnRemoveFile}
              >
                {() => (
                  <aside
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 10,
                    }}
                  >
                    <CButton
                      disabled={uploading}
                      type="button"
                      size="md"
                      onClick={handleOpenDialog}
                      className="btn w-100"
                      color="primary"
                      style={{
                        display: newRoster ? "none" : "block",
                      }}
                    >
                      Choose File
                    </CButton>
                    <CButton
                      disabled={uploading}
                      className="btn btn-danger w-100"
                      size="md"
                      onClick={handleRemoveFile}
                      style={{
                        display: newRoster ? "block" : "none",
                      }}
                    >
                      Remove File
                    </CButton>
                  </aside>
                )}
              </CSVReader>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <CButton
              disabled={!newRoster || uploading}
              color="success"
              size="lg"
              alt="upload the roster"
              className="w-100 px-4"
              style={{ minWidth: "100%", maxWidth: "100%" }}
              onClick={addStudents}
            >
              {uploading ? uploadingText : "Upload Students"}
            </CButton>
          </div>
        </CModalBody>
        <CModalFooter
          style={{
            border: "none",
          }}
        />
      </CModal>
    </div>
  );
};

export default BulkImportStudents;
