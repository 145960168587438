import { FC } from "react";
import { ImageContent, TextContent, VideoContent } from "./content-types";
import { IspringHTMLContent } from "./content-types/ispring-html";
import { YoutubeVideoLinkContent } from "./content-types/youtube-link-content";

export interface TemplateContentProps {
  moduleContent: any;
  setAllowedNext: any;
}

export const TemplateContent: FC<TemplateContentProps> = (props) => {
  const { moduleContent } = props;
  return (
    <div className="d-flex w-100 h-100 align-items-center ">
      {moduleContent.type === "text" && (
        <TextContent content={moduleContent.content} />
      )}
      {moduleContent.type === "image" && (
        <ImageContent content={moduleContent.content} />
      )}
      {moduleContent.type === "video" && (
        <VideoContent content={moduleContent.content} />
      )}
      {moduleContent.type === "youtube-link" && (
        <YoutubeVideoLinkContent content={moduleContent.content} />
      )}
      {moduleContent.type === "ispring-html" && (
        <IspringHTMLContent content={moduleContent.content} />
      )}
    </div>
  );
};
