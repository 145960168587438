import { useContext } from "react";
import { CButton, CTooltip } from "@coreui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { CourseContext } from "../CourseContext";
import CIcon from "@coreui/icons-react";
import { Delete_API } from "../../../../../API/Delete_API";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Course } from "../../../../../store";
import { Patch_API } from "../../../../../API/Patch_API";
import { Post_API } from "../../../../../API/Post_API";
import CopyIframe from "./CopyIframe";

export default function CreateCourseButtons() {
  const { getAccessTokenSilently } = useAuth0();
  const { course, courses, setEdited } = useContext(CourseContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const saving = async () => {
    const token = await getAccessTokenSilently();
    const saving = await Patch_API("/api/courses", { course }, token);
    if (saving.success) {
      window.alert(saving.success);
      setEdited(false);
    }
  };

  const deleteCourse = async () => {
    let r = window.confirm("Are you sure you want to delete this class?");
    if (r) {
      try {
        const token = await getAccessTokenSilently();
        const deleteCourse = await Delete_API(
          `/api/courses`,
          { coursesToDelete: { selected_courses: [course._id] } },
          token
        );
        if (deleteCourse.success) {
          dispatch({
            type: "set",
            courses: [...courses.filter((c: Course) => c._id !== course._id)],
          });
          history.push("/admin/courses");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const duplicateClass = async () => {
    let r = window.confirm("Are you sure you want to duplicate this course?");
    if (r) {
      let token = await getAccessTokenSilently();
      saving();

      let dupCourse: Course = { ...course };
      delete dupCourse["_id"];

      dupCourse.reccomended_groups = [];
      dupCourse.course_title = `${dupCourse.course_title} - Duplicate`;

      let newCourse = await Post_API("/api/courses", { dupCourse }, token);
      if (newCourse.success) {
        history.push(`/admin/courses/${newCourse.success._id}`);
      }
    }
  };

  return (
    <div className="d-flex my-3 flex-row">
      <CTooltip content="Delete this course">
        <CButton color="danger" className="mx-1" onClick={deleteCourse}>
          <CIcon name="cil-trash" />
        </CButton>
      </CTooltip>
      <CTooltip content="View Course">
        <CButton
          color="primary"
          className="mx-1"
          onClick={() => {
            saving();
            window.open(`/course/${course._id}`, "__blank");
          }}
        >
          <CIcon name="cil-find-in-page" />
        </CButton>
      </CTooltip>
      <CTooltip content="Save Course">
        <CButton color="success" className="mx-1" onClick={() => saving()}>
          <CIcon name="cil-save" />
        </CButton>
      </CTooltip>
      <CTooltip content="Duplicate Course">
        <CButton color="primary" className="mx-1" onClick={duplicateClass}>
          <CIcon name="cil-copy" />
        </CButton>
      </CTooltip>
      <CopyIframe />
    </div>
  );
}
