import { useContext } from "react";
import { CourseContext } from "../../CourseContext";
import { ContentEditor } from "./ContentEditor";

export default function Template2() {
  const { module } = useContext(CourseContext);

  return (
    <div
      className="d-flex"
      style={{
        backgroundColor: "#5AB3F7",
        height: "100%",
        width: "100%",
        borderRadius: "20px",
        overflow: "auto",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center w-50 m-3"
        style={{
          backgroundColor:
            module.moduleContent1 === "text" || module.moduleContent1 === ""
              ? "#0278CF"
              : "",
          borderRadius: "3px",
        }}
      >
        <ContentEditor layoutSlot={"moduleContent1"} />
      </div>
      <div className="d-flex flex-column w-50 m-3" style={{}}>
        <div
          className="d-flex flex-column align-items-center justify-content-center w-100"
          style={{
            backgroundColor:
              module.moduleContent2 === "text" || module.moduleContent2 === ""
                ? "#0278CF"
                : "",
            height: "100%",
            borderRadius: "3px",
          }}
        >
          <ContentEditor layoutSlot={"moduleContent2"} />
        </div>
      </div>
    </div>
  );
}
