import {
  cilPeople,
  cilFile,
  cilTrash,
  cilSave,
  cilCopy,
  cilShare,
  cilPlus,
  cilCloudDownload,
  cilCloudUpload,
  cilFindInPage,
  cilArrowRight,
  cilArrowLeft,
  cilCheckCircle,
  cilCircle,
  cilHamburgerMenu,
  cilLockLocked,
  cilList,
  cilApplications,
  cilMinus,
  cilLink,
  cilVideo,
  cilImage,
  cilShortText,
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    cilShortText,
    cilPeople,
    cilFile,
    cilTrash,
    cilLink,
    cilVideo,
    cilImage,
    cilSave,
    cilCopy,
    cilShare,
    cilPlus,
    cilCloudDownload,
    cilCloudUpload,
    cilFindInPage,
    cilArrowRight,
    cilArrowLeft,
    cilCheckCircle,
    cilCircle,
    cilHamburgerMenu,
    cilLockLocked,
    cilList,
    cilMinus,
    cilApplications,
  }
);
