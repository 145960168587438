import { useEffect, useState, useCallback } from "react";
import { CButton } from "@coreui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { Patch_API } from "../../../API/Patch_API";
import { useParams, useHistory } from "react-router-dom";
import { Get_API } from "../../../API/Get_API";
import { Student } from "../../../store";
import Loading from "../../../components/Loading";
const { jsPDF } = require("jspdf");

export interface CourseFinishParams {
  course_id: string;
}

export default function Finish() {
  const { user, getAccessTokenSilently } = useAuth0();
  const { course_id } = useParams<CourseFinishParams>();
  const [student, setStudent] = useState<Student | null>(null);
  const [courseProgress, setCourseProgress] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    const getCourseProgress = async () => {
      const token = await getAccessTokenSilently();
      const progressRequest = await Get_API(
        `/api/course-progress?user_email=${user?.name}&course_id=${course_id}`,
        token
      );
      if (progressRequest.success) {
        setCourseProgress(progressRequest.success);
      }
    };
    getCourseProgress();
  }, [getAccessTokenSilently, user, course_id]);

  useEffect(() => {
    const getStudent = async () => {
      const token = await getAccessTokenSilently();
      const studentRequest = await Get_API(
        `/api/students?student_email=${user?.name}`,
        token
      );
      if (studentRequest.success[0]) {
        studentRequest.success[0].courses.includes(course_id)
          ? setStudent(studentRequest.success[0])
          : setStudent(null);
      }
    };
    getStudent();
  }, [getAccessTokenSilently, user, course_id]);

  const saveProgress = useCallback(
    async (progress: any) => {
      const token = await getAccessTokenSilently();
      await Patch_API(`/api/course-progress`, { ...progress }, token);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseProgress, getAccessTokenSilently]
  );

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(32);
    doc.addImage("/fullLogoDark.png", "PNG", 68, 5, 80, 40);
    doc.setFontSize(16);
    doc.text(
      [
        `Student: ${
          student
            ? student?.first_name + " " + student?.last_name
            : "Test Student"
        }`,
        `Course: ${courseProgress?.course_title}`,
        `Grade: ${(courseProgress?.finalGrade * 100).toFixed()}%`,
        `School: ${
          courseProgress.business
            ? courseProgress?.business
            : "Business Name Not Available"
        }`,
        " ",
        " ",
        "This is an official document that you can share with your teacher in",
        "order to share your progress and grade.",
        " ",
        " ",
        "Please save this document as it is not accessible after you close the window.",
        " ",
        " ",
        `Thank You,`,
        `Crisis Champion`,
      ],
      13,
      50
    );
    doc.save(`${courseProgress?.course_title}.pdf`);
  };

  const resetProgress = async () => {
    let newProgress = { ...courseProgress };
    delete newProgress.finalGrade;
    saveProgress(newProgress);
    history.push(`/course/${course_id}`);
  };

  if (!courseProgress) {
    return <Loading />;
  }

  return (
    <div
      className="d-flex flex-column align-items-start justify-content-center h-100 "
      style={{
        zIndex: 10,
      }}
    >
      <div className="d-flex flex-column">
        <div
          className="d-flex text-center align-items-center justify-content-center h-100"
          style={{
            background: "linear-gradient(0.375turn, #1b2c5f, #0562A5)",
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            overflow: "auto",
            flexWrap: "wrap",
            minHeight: "400px",
          }}
        >
          <h1 className="text-light text-center m-4">
            {courseProgress?.finalGrade > 0.7 ? "Congratulations" : "Hello"},{" "}
            {student?.first_name ?? "TEST STUDENT"}!
          </h1>
          <h3 className="text-light text-center m-4">
            {courseProgress?.finalGrade < 0.7
              ? "Unfortunately, you did not pass this course."
              : "You have successfully completed this course!"}{" "}
            You received a{" "}
            {(courseProgress?.finalGrade * 100).toFixed(1) ?? "N/A"}%.
          </h3>
          <h3 className="text-light text-center m-4">
            Press the button below to{" "}
            {courseProgress?.finalGrade > 0.7
              ? "download this screen for confirmation."
              : "retake this course."}
          </h3>
        </div>
        {courseProgress?.finalGrade > 0.7 ? (
          <CButton onClick={downloadPDF} color="success" className="my-2">
            Download PDF
          </CButton>
        ) : (
          <CButton
            color="danger"
            className="my-2"
            onClick={() => {
              resetProgress();
            }}
          >
            Retake Course
          </CButton>
        )}
      </div>
    </div>
  );
}
