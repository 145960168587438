import AdminCustomersList from "./pages/Admin/Customers/CustomersList";
import AdminCoursesList from "./pages/Admin/Courses/CoursesList";
import AdminCustomerView from "./pages/Admin/Customer/CustomerView";
import Customer from "./pages/Customer/CustomerDash";
import Course from "./pages/Course/CourseDash";
import EditCourse from "./pages/Admin/Courses/Course/EditCourse";
import CustomerDash from "./pages/Customer/CustomerDash";
import Finish from "./pages/Course/Details/Finish";
import AdminsList from "./pages/Admin/Admins/AdminsList";
import MyCourses from "./pages/Student/MyCourses";

const routes = [
  {
    path: "/admin/customers",
    exact: true,
    name: "Admin Customers List",
    component: AdminCustomersList,
  },
  {
    path: "/admin/admins",
    exact: true,
    name: "Admin Customers List",
    component: AdminsList,
  },
  {
    path: "/customer/students/:_id",
    exact: true,
    name: "Admin Customer View",
    component: AdminCustomerView,
  },
  {
    path: "/customer/dash/:customer_id",
    exact: true,
    name: "Admin Customer View",
    component: CustomerDash,
  },
  {
    path: "/admin/courses",
    exact: true,
    name: "Admin Courses List",
    component: AdminCoursesList,
  },
  {
    path: "/admin/courses/:_id",
    exact: true,
    name: "Course Editor",
    component: EditCourse,
  },
  {
    path: "/course/grade/:course_id",
    exact: true,
    name: "Course Complete",
    component: Finish,
  },
  {
    path: "/customer",
    component: Customer,
  },
  {
    path: "/course/:course_id/:module_id?",
    exact: false,
    component: Course,
  },
  {
    path: "/",
    exact: false,
    component: MyCourses,
  },
];

export default routes;
