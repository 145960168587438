import {
  CImg,
  CSidebar,
  CSidebarBrand,
  CSidebarNavItem,
  CSidebarNav,
  CSidebarFooter,
  CButton,
} from "@coreui/react";
import { useSelector } from "react-redux";
import { IState } from "../store";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

const TheSidebar = () => {
  const { logout } = useAuth0();
  const sidebarShow = useSelector((state: IState) => state.sidebarShow);
  const user: any = useSelector((state: IState) => state.user);
  return (
    <StyledSidebar show={sidebarShow} colorScheme="dark">
      <CSidebarBrand
        style={{
          background: "none",
        }}
      >
        <a href="https://crisischampion.com" target="_blank" rel="noreferrer">
          <CImg
            src={`${process.env.PUBLIC_URL}/logo.png`}
            width="50%"
            className="my-2"
          />
        </a>
      </CSidebarBrand>
      <CSidebarNav>
        {user.role <= 1 && (
          <>
            <StyledCSidebarNavItem
              icon="cil-people"
              className="my-2"
              color="primary"
              to="/admin/customers"
              style={{ borderRadius: "10px" }}
            />
            <small>Customers</small>
            <StyledCSidebarNavItem
              icon="cil-file"
              className="my-2"
              color="primary"
              to="/admin/courses"
              style={{ borderRadius: "10px" }}
            />
            <small>Courses</small>
            <StyledCSidebarNavItem
              icon="cil-lock-locked"
              className="my-2"
              color="primary"
              to="/admin/admins"
              style={{ borderRadius: "10px" }}
            />
            <small>Admins</small>
          </>
        )}
        {user.role === 2 && (
          <>
            <StyledCSidebarNavItem
              icon="cil-applications"
              className="my-2"
              color="primary"
              to={`/customer/dash/${user._id}`}
              style={{ borderRadius: "10px" }}
            />
            <small>Dashboard</small>
            <StyledCSidebarNavItem
              icon="cil-people"
              className="my-2"
              color="primary"
              to={`/customer/students/${user._id}`}
              style={{ borderRadius: "10px" }}
            />
            <small>Students</small>
          </>
        )}
        <StyledCSidebarNavItem
          icon="cil-list"
          className="my-2"
          color="primary"
          to="/"
          style={{ borderRadius: "10px" }}
        />
        <small>My Courses</small>
      </CSidebarNav>
      <CSidebarFooter style={{ background: "rgba(0,0,0,0)" }}>
        <CSidebarNav>
          <CButton
            color="primary"
            className="my-2"
            style={{ fontSize: ".70rem" }}
            onClick={logout}
          >
            Logout
          </CButton>
          <CButton
            color="primary"
            className="my-2"
            style={{ fontSize: ".60rem" }}
            onClick={() => window.open("http://www.yahoo.com", "_blank")}
          >
            COPPA Info / Privacy
          </CButton>
        </CSidebarNav>
      </CSidebarFooter>
    </StyledSidebar>
  );
};

export default TheSidebar;

const StyledSidebar = styled(CSidebar)`
  width: 115px;
`;

const StyledCSidebarNavItem = styled(CSidebarNavItem)`
  width: 50%;
  align-self: center;
`;
