import React, { useRef, useEffect, useState } from "react";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CLabel,
  CInput,
  CTooltip,
} from "@coreui/react";
import { Formik, Field, FormikProps } from "formik";
import { Post_API } from "../../../API/Post_API";
import { Patch_API } from "../../../API/Patch_API";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { Customer, Student } from "../../../store";
import CIcon from "@coreui/icons-react";

export interface StudentModalProps {
  students: Student[];
  student?: Student | null;
  customer: Customer;
  toggle: () => void;
  modal: boolean;
  handleStudentDeSelect: () => void;
}

const StudentModal: React.FC<StudentModalProps> = ({
  students,
  student,
  toggle,
  modal,
  handleStudentDeSelect,
  customer,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<any>>(null);
  const [groupList, setGroupList] = useState<string[]>([]);

  useEffect(() => {
    let groups: any = new Set();
    students.forEach((stud) => groups.add(stud.group));
    setGroupList([...groups]);
  }, [students]);

  const handleSubmit = async (values: any) => {
    let token = await getAccessTokenSilently();
    if (!student) {
      let newStudent = await Post_API("/api/students", values, token);
      dispatch({ type: "set", students: [...students, newStudent.success] });
      formRef.current?.handleReset();
      toggle();
    } else {
      let updatedStudent = await Patch_API(
        `/api/students/${student!._id}`,
        values,
        token
      );
      students[
        students.findIndex((cust) => cust._id === updatedStudent.success._id)
      ] = updatedStudent.success;
      dispatch({
        type: "set",
        students,
      });
      formRef.current?.handleReset();
      toggle();
    }
  };

  return (
    <>
      <CTooltip content="Add New Student">
        <CButton
          size="md"
          className="ml-2"
          color="success"
          onClick={() => {
            handleStudentDeSelect();
            toggle();
          }}
        >
          <CIcon name="cil-plus" />
        </CButton>
      </CTooltip>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={{
          first_name: student?.first_name || "",
          last_name: student?.last_name || "",
          email: student?.email || "",
          group: student?.group || "",
          customer: customer._id,
        }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(form) => (
          <>
            <CModal
              show={modal}
              onClose={() => {
                handleStudentDeSelect();
              }}
            >
              <CModalHeader></CModalHeader>
              <CModalBody>
                <CLabel className="my-2">First Name</CLabel>
                <Field
                  name="first_name"
                  placeholder="First Name"
                  value={form.values.first_name}
                  as={CInput}
                  className="custom-input"
                />
                <CLabel className="my-2">Last Name</CLabel>
                <Field
                  name="last_name"
                  placeholder="Last Name"
                  value={form.values.last_name}
                  as={CInput}
                  className="custom-input"
                />
                <CLabel className="my-2">Email</CLabel>
                <Field
                  name="email"
                  type="email"
                  placeholder="Student Email"
                  value={form.values.email}
                  as={CInput}
                  className="custom-input"
                />
                <CLabel className="my-2">Group</CLabel>
                <Field
                  name="group"
                  value={form.values.group}
                  placeholder="Group/Age Group"
                  as={CInput}
                  className="custom-input"
                  list="studentGroupsList"
                />
                <datalist id="studentGroupsList">
                  {groupList.map((group) => (
                    <option key={group} value={group} />
                  ))}
                </datalist>
              </CModalBody>
              <CModalFooter>
                <CButton
                  onClick={form.handleSubmit}
                  size="md"
                  color="success"
                  disabled={!form.isValid}
                >
                  {student ? "Save Student" : "Create Student"}
                </CButton>
              </CModalFooter>
            </CModal>
          </>
        )}
      </Formik>
    </>
  );
};

export default StudentModal;
