import { FC, useContext } from "react";
import { TakeCourseContext } from "../../../TakeCourseContext";
import { TemplateContent } from "./template-content";

const Template3: FC = () => {
  const { selectedModule, setAllowedNext } = useContext(TakeCourseContext);

  return (
    <div
      className="d-flex"
      style={{
        background: "linear-gradient(0.375turn, #1b2c5f, #0562A5)",
        height: "100%",
        width: "100%",
        borderRadius: "10px",
        overflow: "auto",
        minHeight: "400px",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center w-50 m-3"
        style={{
          // backgroundColor: selectedModule.moduleContent1.type === 'text' || selectedModule.moduleContent1 === '' ? '#0278CF' : '',
          borderRadius: "3px",
          overflow: "auto",
        }}
      >
        <TemplateContent
          moduleContent={selectedModule?.moduleContent1}
          setAllowedNext={setAllowedNext}
        />
      </div>
      <div className="d-flex flex-column w-50 m-3">
        <div
          className="d-flex flex-column align-items-center justify-content-center w-100"
          style={{
            height: "50%",
            borderBottom: "solid 3px rgb( 0, 0, 0, 0.0)",
            borderRadius: "3px",
          }}
        >
          <TemplateContent
            moduleContent={selectedModule?.moduleContent2}
            setAllowedNext={setAllowedNext}
          />
        </div>
        <div
          className="d-flex flex-column align-items-center justify-content-center w-100"
          style={{
            // backgroundColor: selectedModule.moduleContent3.type === 'text' || selectedModule.moduleContent3 === '' ? '#0278CF' : '',
            height: "50%",
            borderBottom: "solid 3px rgb( 0, 0, 0, 0.0)",
            borderRadius: "3px",
          }}
        >
          <TemplateContent
            moduleContent={selectedModule?.moduleContent3}
            setAllowedNext={setAllowedNext}
          />
        </div>
      </div>
    </div>
  );
};

export default Template3;
