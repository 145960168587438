import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { CImg } from "@coreui/react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { CourseContext } from "../../../../../CourseContext";
import { Post_API } from "../../../../../../../../../API/Post_API";
import { Delete_API } from "../../../../../../../../../API/Delete_API";
import { Get_API } from "../../../../../../../../../API/Get_API";
import { MediaModal } from "../../../../../../../../../components/media-upload";

export interface ImageContentProps {
  slot: string;
}

export const ImageContent: React.FC<ImageContentProps> = ({ slot }) => {
  let { course, setCourse, module } = useContext(CourseContext);
  const [modal, setModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const imgDivRef = useRef<HTMLDivElement | null>(null);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal);
  };

  useEffect(() => {
    setSelectedImage(module[slot].content);
  }, [module, slot]);

  const getAllImgs = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const images = await Get_API("/api/media?type=image", token);
      if (images.success) {
        setImages(images.success);
      }
    } catch (error) {
      console.log(error);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getAllImgs();
  }, [getAccessTokenSilently, getAllImgs]);

  const handleUpload = (values: { file: any; fileName: string }) => {
    const formData = new FormData();
    formData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET!
    );
    formData.append("file", values.file);

    axios
      .post(process.env.REACT_APP_CLOUDINARY_URI!, formData)
      .then((res) => {
        setImages((imgs: any) => [
          ...imgs,
          {
            type: "image",
            link: res.data.secure_url,
          },
        ]);
        saveImage(res.data.secure_url, values.fileName);
        setSelectedImage(res.data.secure_url);
        saveImageToModule(res.data.secure_url);
      })
      .catch((err) => console.log(err));
    toggleUploadModal();
    toggle();
  };

  const saveImage = async (link: string, fileName: string) => {
    let newMedia = {
      type: "image",
      link: link,
      title: fileName,
    };

    try {
      const token = await getAccessTokenSilently();
      await Post_API("/api/media", newMedia, token);
      getAllImgs();
    } catch (error) {
      console.log(error);
    }
  };

  const saveImageToModule = (link: any) => {
    module = {
      ...module,
      [slot]: {
        ...module[slot],
        content: link,
      },
    };

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );
    course.modules.splice(indexOfModule, 1, module);

    setCourse(course);
  };

  const handleRemoveFromModule = () => {
    module = {
      ...module,
      [slot]: "",
    };

    let indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );
    course.modules.splice(indexOfModule, 1, module);

    setSelectedImage(null);

    setCourse(course);
  };

  const handleDelete = async (mediaToDelete: any) => {
    let token = await getAccessTokenSilently();
    let deleteImages = await Delete_API("/api/media", { mediaToDelete }, token);
    if (deleteImages.success) {
      getAllImgs();
    }
  };

  return (
    <div
      ref={imgDivRef}
      className="d-flex flex-column justify-content-center align-items-center w-100"
      style={{
        overflow: "hidden",
        borderRadius: "5px",
      }}
    >
      {selectedImage ? (
        <>
          <CIcon
            name="cil-trash"
            size="4xl"
            style={{ position: "absolute", pointerEvents: "none" }}
          />
          <StyledCImg
            src={selectedImage}
            width="100%"
            onClick={handleRemoveFromModule}
          />
        </>
      ) : (
        <MediaModal
          media={images}
          handleMediaSelect={saveImageToModule}
          handleDelete={handleDelete}
          handleUpload={handleUpload}
          useTrigger={selectedImage ? false : true}
        />
      )}
    </div>
  );
};

const StyledCImg = styled(CImg)`
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
  &:hover {
    opacity: 0.5;
  }
`;
