import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import CIcon from "@coreui/icons-react";
import { CourseContext } from "../../../../../CourseContext";
import {
  CButton,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupAppend,
} from "@coreui/react";

export interface YoutubeLinkContentProps {
  slot: string;
}

export const YoutubeLinkContent: React.FC<YoutubeLinkContentProps> = ({
  slot,
}) => {
  let { course, setCourse, module } = useContext(CourseContext);
  const [hovering, setHovering] = useState(false);
  const [youtubeId, setYoutubeId] = useState<string | null>(null);

  useEffect(() => {
    setYoutubeId(module[slot].content);
  }, [module, slot]);

  const saveVideoToModule = () => {
    module = {
      ...module,
      [slot]: {
        ...module[slot],
        content: youtubeId,
      },
    };

    const indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );
    course.modules.splice(indexOfModule, 1, module);

    setCourse(course);
  };

  const handleRemoveFromModule = () => {
    module = {
      ...module,
      [slot]: "",
    };

    const indexOfModule = course.modules.findIndex(
      (mod: any) => mod.id === module.id
    );
    course.modules.splice(indexOfModule, 1, module);

    setYoutubeId(null);

    setCourse(course);
  };

  function youtube_parser(url: string) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    // eslint-disable-next-line eqeqeq
    return match && match[7].length == 11 ? match[7] : false;
  }

  const handleYoutubeLinkChange = (link: string) => {
    const parsedYoutubeId = youtube_parser(link);
    if (parsedYoutubeId) {
      setYoutubeId(parsedYoutubeId);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      {module[slot].content ? (
        <>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${module[slot].content}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
          <CIcon
            name="cil-trash"
            color="red"
            size="2xl"
            style={{
              color: "red",
              position: "absolute",
              cursor: "pointer",
              opacity: hovering ? "1" : "0.3",
              zIndex: 1000,
            }}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onClick={handleRemoveFromModule}
          />
        </>
      ) : (
        <CContainer className="d-flex justify-content-center align-items-center h-100 w-100 border rounded">
          <CInputGroup>
            <StyledVideoCInput
              placeholder="Youtube Link"
              onChange={(e) =>
                handleYoutubeLinkChange((e.target as HTMLInputElement).value)
              }
            />
            <CInputGroupAppend>
              <CButton color="success" onClick={saveVideoToModule}>
                Embed
              </CButton>
            </CInputGroupAppend>
          </CInputGroup>
        </CContainer>
      )}
    </div>
  );
};

const StyledVideoCInput = styled(CInput)`
  &::placeholder {
    color: black !important;
    text-align: center;
  }
`;
