import React, { useRef } from "react";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CLabel,
  CInput,
  CCol,
  CTooltip,
} from "@coreui/react";
import { Formik, Field, FormikProps } from "formik";
import { Post_API } from "../../../API/Post_API";
import { Patch_API } from "../../../API/Patch_API";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { Admin } from "../../../store";
import CIcon from "@coreui/icons-react";

export interface AdminModalProps {
  admins: Admin[];
  admin?: Admin | null;
  toggle: () => void;
  modal: boolean;
  handleAdminDeSelect: () => void;
}

const AdminModal: React.FC<AdminModalProps> = ({
  admins,
  admin,
  toggle,
  modal,
  handleAdminDeSelect,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<any>>(null);

  const handleSubmit = async (values: any) => {
    let token = await getAccessTokenSilently();
    if (!admin) {
      let newAdmin = await Post_API("/api/admins", values, token);
      dispatch({ type: "set", admins: [...admins, newAdmin.success] });
      formRef.current?.handleReset();
      toggle();
    } else {
      let updatedAdmin = await Patch_API(
        `/api/admins/${admin!._id}`,
        values,
        token
      );
      admins[
        admins.findIndex((cust) => cust._id === updatedAdmin.success._id)
      ] = updatedAdmin.success;
      dispatch({
        type: "set",
        admin,
      });
      formRef.current?.handleReset();
      toggle();
    }
  };

  return (
    <>
      <CTooltip content="Add New Admin">
        <CButton
          size="md"
          className="ml-2"
          color="success"
          onClick={() => {
            handleAdminDeSelect();
            toggle();
          }}
        >
          <CIcon name="cil-plus" />
        </CButton>
      </CTooltip>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={{
          first_name: admin?.first_name || "",
          last_name: admin?.last_name || "",
          email: admin?.email || "",
        }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(form) => (
          <>
            <CModal
              show={modal}
              size="lg"
              onClose={() => {
                handleAdminDeSelect();
              }}
            >
              <CModalHeader>
                <span className="modal-title">Admin</span>
              </CModalHeader>
              <CModalBody>
                <CCol>
                  <CLabel className="my-2">First Name</CLabel>
                  <Field
                    name="first_name"
                    value={form.values.first_name}
                    as={CInput}
                    className="custom-input"
                  />
                  <CLabel className="my-2">Last Name</CLabel>
                  <Field
                    name="last_name"
                    value={form.values.last_name}
                    as={CInput}
                    className="custom-input"
                  />
                  <CLabel className="my-2">Email</CLabel>
                  <Field
                    name="email"
                    type="email"
                    value={form.values.email}
                    as={CInput}
                    className="custom-input"
                  />
                </CCol>
              </CModalBody>
              <CModalFooter>
                <CButton
                  onClick={form.handleSubmit}
                  size="md"
                  color="success"
                  disabled={!form.isValid}
                >
                  {admin ? "Save Admin" : "Create Admin"}
                </CButton>
              </CModalFooter>
            </CModal>
          </>
        )}
      </Formik>
    </>
  );
};

export default AdminModal;
