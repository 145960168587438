import React, { useRef } from "react";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CButton,
  CLabel,
  CInput,
  CCol,
  CTextarea,
  CRow,
  CImg,
  CInputFile,
  CTooltip,
} from "@coreui/react";
import { Formik, Field, FormikProps } from "formik";
import axios from "axios";
import { Post_API } from "../../../API/Post_API";
import { Patch_API } from "../../../API/Patch_API";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { Customer } from "../../../store";
import { useHistory } from "react-router-dom";
import CIcon from "@coreui/icons-react";

export interface CustomerModalProps {
  customers: Customer[];
  customer?: Customer | null;
  toggle: () => void;
  modal: boolean;
  handleCustomerDeSelect: () => void;
}

const CustomerModal: React.FC<CustomerModalProps> = ({
  customers,
  customer,
  toggle,
  modal,
  handleCustomerDeSelect,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<any>>(null);
  const history = useHistory();

  const handleSubmit = async (values: any) => {
    let token = await getAccessTokenSilently();
    if (!customer) {
      let newCustomer = await Post_API("/api/customers", values, token);
      dispatch({ type: "set", customers: [...customers, newCustomer.success] });
      formRef.current?.handleReset();
      toggle();
    } else {
      let updatedCustomer = await Patch_API(
        `/api/customers/${customer!._id}`,
        values,
        token
      );
      customers[
        customers.findIndex((cust) => cust._id === updatedCustomer.success._id)
      ] = updatedCustomer.success;
      dispatch({
        type: "set",
        customers,
      });
      formRef.current?.handleReset();
      toggle();
    }
  };

  const handleUpload = (file: any, setFieldValue: any) => {
    const formData = new FormData();
    formData.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET!
    );
    formData.append("file", file);

    axios
      .post(process.env.REACT_APP_CLOUDINARY_URI!, formData)
      .then((res: any) => {
        setFieldValue("school_img", res.data.secure_url);
      })
      .catch((err: any) => console.log(err));
  };

  return (
    <>
      <CTooltip content="Add New Customer">
        <CButton
          size="md"
          className="ml-2"
          color="success"
          onClick={() => {
            handleCustomerDeSelect();
            toggle();
          }}
        >
          <CIcon name="cil-plus" />
        </CButton>
      </CTooltip>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={{
          business: customer?.business || "",
          first_name: customer?.first_name || "",
          last_name: customer?.last_name || "",
          email: customer?.email || "",
          student_message: customer?.student_message || "",
          school_img: customer?.school_img || "",
        }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(form) => (
          <>
            <CModal
              show={modal}
              size="lg"
              onClose={() => {
                handleCustomerDeSelect();
              }}
            >
              <CModalHeader>
                <span className="modal-title">Customer</span>
              </CModalHeader>
              <CModalBody>
                <CRow>
                  <CCol lg="6">
                    <CLabel className="my-2">Business Name</CLabel>
                    <Field
                      name="business"
                      value={form.values.business}
                      as={CInput}
                      className="custom-input"
                    />
                    <CLabel className="my-2">First Name</CLabel>
                    <Field
                      name="first_name"
                      value={form.values.first_name}
                      as={CInput}
                      className="custom-input"
                    />
                    <CLabel className="my-2">Last Name</CLabel>
                    <Field
                      name="last_name"
                      value={form.values.last_name}
                      as={CInput}
                      className="custom-input"
                    />
                    <CLabel className="my-2">Email</CLabel>
                    <Field
                      name="email"
                      type="email"
                      value={form.values.email}
                      as={CInput}
                      className="custom-input"
                    />
                  </CCol>
                  <CCol lg="6">
                    <Field
                      name="student_message"
                      value={form.values.student_message}
                      placeholder="Student welcome message..."
                      as={CTextarea}
                      className="custom-textarea"
                    />
                    <CImg
                      src={
                        form.values.school_img
                          ? form.values.school_img
                          : customer?.school_img
                      }
                      width="100%"
                      required
                      style={{
                        margin: "1rem 0",
                        border: !form.values.school_img
                          ? "1px dashed black"
                          : "",
                      }}
                    />
                    <CInputFile
                      className="custom-file-input"
                      hidden
                      id="custom-file"
                      onChange={(e: any) =>
                        handleUpload(e.target.files[0], form.setFieldValue)
                      }
                    />
                    <CLabel
                      htmlFor="custom-file"
                      className="custom-file-label position-relative w-100 text-left"
                      style={{ cursor: "pointer" }}
                    >
                      Choose Picture...
                    </CLabel>
                  </CCol>
                </CRow>
              </CModalBody>
              <CModalFooter>
                {customer && (
                  <CButton
                    size="md"
                    color="primary"
                    onClick={() =>
                      history.push({
                        pathname: `/customer/dash/${customer?._id}`,
                        state: { customer: customer },
                      })
                    }
                  >
                    View Customer Dash
                  </CButton>
                )}
                {customer && (
                  <CButton
                    size="md"
                    color="primary"
                    onClick={() =>
                      history.push({
                        pathname: `/customer/students/${customer?._id}`,
                        state: { customer: customer },
                      })
                    }
                  >
                    View Students
                  </CButton>
                )}
                <CButton
                  onClick={form.handleSubmit}
                  size="md"
                  color="success"
                  disabled={!form.isValid}
                >
                  {customer ? "Save Customer" : "Create Customer"}
                </CButton>
              </CModalFooter>
            </CModal>
          </>
        )}
      </Formik>
    </>
  );
};

export default CustomerModal;
