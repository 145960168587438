import React, { useContext } from "react";
import {
  CSidebar,
  CSidebarNav,
  CSidebarNavItem,
  CSidebarNavTitle,
} from "@coreui/react";
import { TakeCourseContext } from "../../TakeCourseContext";
import { CourseModule } from "../../../Admin/Courses/Course/Details/CourseModules";

const CourseNavigationAside: React.FC = () => {
  const {
    course,
    aside,
    toggleAside,
    course_id,
    courseProgress,
    updateProgress,
  } = useContext(TakeCourseContext);
  return (
    <CSidebar aside show={aside} onShowChange={toggleAside} overlaid size="">
      <CSidebarNav>
        <CSidebarNavTitle>
          {course?.course_title ?? "Course Modules"}
        </CSidebarNavTitle>
        <CSidebarNavItem name="Welcome" icon="" to={`/course/${course_id}`} />
        {course?.modules.map((mod: CourseModule) => {
          const allowedToClick = !!courseProgress.course_progress?.[mod.id];
          return (
            <CSidebarNavItem
              key={mod.id}
              icon={
                courseProgress.course_progress
                  ? Object.keys(courseProgress.course_progress).includes(mod.id)
                    ? "cil-check-circle"
                    : "cil-circle"
                  : "cil-circle"
              }
              name={mod.title}
              to={
                allowedToClick ? `/course/${course._id}/${mod.id}` : undefined
              }
              onClick={() =>
                allowedToClick
                  ? updateProgress(mod.id)
                  : window.alert("Please complete preceding module first.")
              }
            />
          );
        })}
      </CSidebarNav>
    </CSidebar>
  );
};

export default CourseNavigationAside;
