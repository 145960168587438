import { FC } from "react";
import {
  CButton,
  CCollapse,
  CContainer,
  CInput,
  CInputFile,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CSpinner,
  CTooltip,
} from "@coreui/react";
import { Formik } from "formik";
import CIcon from "@coreui/icons-react";
import styled from "styled-components";

export interface UploadModalProps {
  handleUpload: (v: any) => void;
  showCollapse: boolean;
  fileType?: string;
}

export const UploadCollapse: FC<UploadModalProps> = ({
  handleUpload,
  showCollapse,
  fileType,
}) => {
  return (
    <>
      <CCollapse
        show={showCollapse}
        style={{ position: "relative", width: "75%" }}
      >
        <Formik
          initialValues={{ file: {} as HTMLInputElement, fileName: "" }}
          onSubmit={(values, helpers) => {
            helpers.setSubmitting(true);
            handleUpload(values);
          }}
        >
          {(form) => (
            <CContainer>
              <CInputFile
                type="file"
                className="custom-file-input w-75"
                style={{ height: 0 }}
                id="customFileInput"
                name="files"
                onChange={(e: any) => {
                  form.setFieldValue("file", e.target.files[0]);
                  form.setFieldValue("fileName", e.target.files[0].name);
                }}
              />
              <CInputGroup>
                <CInputGroupPrepend>
                  <CButton
                    color="success"
                    size="sm"
                    onClick={() =>
                      document.getElementById("customFileInput")?.click()
                    }
                  >
                    <CIcon name="cil-file" />
                  </CButton>
                </CInputGroupPrepend>
                <StyledCInput
                  type="text"
                  htmlFor="customFile"
                  name="fileName"
                  value={form.values.fileName}
                  onClick={() =>
                    !form.values.file.name &&
                    document.getElementById("customFileInput")?.click()
                  }
                  placeholder="Choose a file to upload."
                  onChange={form.handleChange}
                />
                <CInputGroupAppend>
                  <CTooltip
                    content={
                      fileType
                        ? fileType !== form.values.file.type
                          ? `Filetype must be of type ${fileType}.`
                          : "Click here to upload."
                        : "Click here when you are ready to upload your file."
                    }
                  >
                    <CButton
                      color="success"
                      alt="Approve File Upload"
                      disabled={
                        !form.values.file.name || form.isSubmitting
                        // (fileType ? fileType !== form.values.file.type : false)
                      }
                      onClick={form.handleSubmit}
                    >
                      {form.isSubmitting ? <CSpinner size="sm" /> : "Upload"}
                    </CButton>
                  </CTooltip>
                </CInputGroupAppend>
              </CInputGroup>
            </CContainer>
          )}
        </Formik>
      </CCollapse>
    </>
  );
};

export const StyledCInput = styled(CInput)`
  &::placeholder {
    color: black !important;
    text-align: center;
  }
`;
