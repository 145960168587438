import { FC } from "react";

export interface ImageContentProps {
  content: string;
}

export const ImageContent: FC<ImageContentProps> = ({ content }) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center m-2"
      style={{
        color: "white",
        width: "100%",
      }}
    >
      <img
        src={content}
        style={{ borderRadius: "5px", maxWidth: "60%" }}
        alt="Course Content"
      />
    </div>
  );
};
