import React, { useState, useEffect } from "react";
import { CSelect } from "@coreui/react";
import { Course, Student } from "../../store";
import { useAuth0 } from "@auth0/auth0-react";
import { Get_API } from "../../API/Get_API";

export interface CompChartProps {
  customerCourses: Course[] | null;
  students: Student[] | null;
  setSelectedGroup: (course: any) => void;
}

const CompChart: React.FC<CompChartProps> = ({
  customerCourses,
  students,
  setSelectedGroup,
}) => {
  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const [groupList, setGroupList] = useState<any>([]);
  const [groupListTotals, setGroupListTotals] = useState<any>([]);
  const [courseProgresses, setCourseProgresses] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const createStudentGroupsList = () => {
      let groups = new Set();
      students?.forEach((stud) => groups.add(stud.group));
      setGroupList([...groups]);
    };
    createStudentGroupsList();
  }, [students]);

  useEffect(() => {
    if (!selectedCourse) {
      return;
    }
    const getCourseProgress = async () => {
      const token = await getAccessTokenSilently();
      const progressRequest = await Get_API(
        `/api/course-progress?course_id=${selectedCourse._id}`,
        token
      );
      if (progressRequest.success) {
        setCourseProgresses(progressRequest.success);
      }
    };
    getCourseProgress();
  }, [getAccessTokenSilently, selectedCourse]);

  useEffect(() => {
    const calculatePercentage = () => {
      //Calc total students in this course.

      //Calc total passed and failed
      groupList.forEach((group: any) => {
        let totalPassed = 0;
        let totalFailed = 0;
        let totalStudentsInGroup = 0;

        students?.forEach((stud) => {
          if (
            selectedCourse.reccomended_groups.includes(stud.group) &&
            stud.group === group
          ) {
            totalStudentsInGroup += 1;
            courseProgresses.forEach((prog: any) => {
              if (prog.course_id === selectedCourse?._id) {
                if (prog.finalGrade) {
                  prog.finalGrade > 0.7
                    ? (totalPassed += 1)
                    : (totalFailed += 1);
                }
              }
            });
          }
        });

        const percentFailed = (totalFailed / totalStudentsInGroup) * 100;
        const percentPassed = (totalPassed / totalStudentsInGroup) * 100;
        const percentInProgress =
          ((totalStudentsInGroup - (totalFailed + totalPassed)) /
            totalStudentsInGroup) *
          100;

        let addedTotals = {
          groupName: group,
          passed: totalPassed,
          failed: totalFailed,
          inProgress: totalStudentsInGroup - (totalFailed + totalPassed),
          groupTotal: totalStudentsInGroup,
          percentFailed,
          percentPassed,
          percentInProgress,
        };

        //Save total passed and failed to state.
        setGroupListTotals((gs: any) => [
          ...gs.filter((g: any) => g.groupName !== addedTotals.groupName),
          addedTotals,
        ]);
      });
    };
    selectedCourse && calculatePercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse, groupList, students]);

  return (
    <div
      className="d-flex justify-content-center align-items-center w-100 h-100"
      style={{}}
    >
      <div
        className="d-flex flex-column align-items-center justify-content-between m-3 w-100 p-3"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          // height: "90%",
          height: "400px",
        }}
      >
        <CSelect
          className="w-50"
          style={{
            backgroundColor: "rgb(4, 109, 199, 0.24)",
            border: "none",
            color: "black",
            textAlign: "center",
          }}
          onChange={(e: any) => {
            if (e.target.value !== "") {
              let newCourse = customerCourses?.filter(
                (c) => c._id === e.target.value
              )[0];
              setSelectedCourse(newCourse);
            } else if (e.target.value === "") {
              setSelectedCourse(null);
            }
          }}
        >
          <option value="">Choose Course</option>
          {customerCourses?.map((course) => {
            return (
              <option key={course._id} value={course._id}>
                {course.course_title}
              </option>
            );
          })}
        </CSelect>
        <div
          className="d-flex flex-row w-100 h-100 m-3 justify-content-center"
          style={{
            flexWrap: "wrap",
            overflowX: "auto",
          }}
        >
          {selectedCourse ? (
            groupList.map((group: any) => {
              if (selectedCourse.reccomended_groups.includes(group)) {
                return (
                  <div
                    id="barChild"
                    key={group}
                    className="d-flex flex-column m-2 text-center"
                    style={{
                      width: "100px",
                      flex: "0 0 auto",
                      height: "85%",
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      onMouseEnter={() => {
                        setSelectedGroup(
                          groupListTotals.length > 0 &&
                            groupListTotals.filter(
                              (t: any) => t.groupName === group
                            )[0]
                        );
                      }}
                      onMouseLeave={() => {
                        setSelectedGroup(null);
                      }}
                      style={{
                        boxShadow: "rgb(119, 119, 119) 10px 10px 8px -6px",
                        flex: "0 0 auto",
                        height: "90%",
                      }}
                    >
                      <div
                        className="d-flex w-100"
                        style={{
                          backgroundColor: "#5bc0de",
                          height: `${
                            groupListTotals.length > 0 &&
                            groupListTotals.filter(
                              (t: any) => t.groupName === group
                            )[0].percentInProgress
                          }%`,
                        }}
                      />
                      <div
                        className="d-flex w-100"
                        style={{
                          backgroundColor: "#DB8B8B",
                          height: `${
                            groupListTotals.length > 0 &&
                            groupListTotals.filter(
                              (t: any) => t.groupName === group
                            )[0].percentFailed
                          }%`,
                        }}
                      />
                      <div
                        className="d-flex w-100"
                        style={{
                          backgroundColor: "#4BB543",
                          height: `${
                            groupListTotals.length > 0 &&
                            groupListTotals.filter(
                              (t: any) => t.groupName === group
                            )[0].percentPassed
                          }%`,
                        }}
                      />
                    </div>
                    <span
                      className="mt-3 w-100"
                      style={{
                        height: "10%",
                        color: "black",
                      }}
                    >
                      {group}
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })
          ) : (
            <div className="align-self-center text-center w-100 text-dark">
              Please select a class.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompChart;
