import { useState, useEffect, useContext } from "react";
import { CourseModule } from "../../CourseModules";
import { CourseContext } from "../../../CourseContext";
import { SelectTypeButton } from "./SelectTypeButton";
import {
  ImageContent,
  TextContent,
  VideoContent,
  YoutubeLinkContent,
} from "./ContentTypes";
import { IspringHTMLCreateContent } from "./ContentTypes/IspringHtmlContent";

export interface ContentEditorProps {
  layoutSlot: string;
}

export const ContentEditor: React.FC<ContentEditorProps> = ({ layoutSlot }) => {
  const { course, setCourse, module, setModuleSelected } =
    useContext(CourseContext);
  const [type, setType] = useState("");
  const [slot, setSlot] = useState("");

  useEffect(() => {
    setSlot(layoutSlot);
  }, [layoutSlot]);

  useEffect(() => {
    if (module[layoutSlot].type) {
      setType(module[layoutSlot].type);
    } else {
      setType("");
    }
  }, [module, layoutSlot]);

  const saveType = (contentType: string) => {
    setType(contentType);

    module[layoutSlot] = { type: contentType };

    setModuleSelected(module.id);

    let indexOfModule = course.modules.findIndex(
      (mod: CourseModule) => mod.id === module.id
    );
    course.modules.splice(indexOfModule, 1, module);

    setCourse(course);
  };

  return (
    <div className="d-flex w-100 h-100">
      {type === "" && (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <SelectTypeButton
            icon="cil-short-text"
            tooltipContent="Add custom text."
            onClick={() => saveType("text")}
          />
          <SelectTypeButton
            icon="cil-image"
            tooltipContent="Upload a Custom Image"
            onClick={() => saveType("image")}
          />
          <SelectTypeButton
            icon="cil-video"
            tooltipContent="Upload a Custom Video"
            onClick={() => saveType("video")}
          />
          <SelectTypeButton
            icon="cil-link"
            tooltipContent="Link a Youtube Video"
            onClick={() => saveType("youtube-link")}
          />
          <SelectTypeButton
            icon="cil-file"
            tooltipContent="Embed an Ispring Course"
            onClick={() => saveType("ispring-html")}
          />
        </div>
      )}
      {type === "text" && <TextContent slot={slot} />}
      {type === "image" && <ImageContent slot={slot} />}
      {type === "video" && <VideoContent slot={slot} />}
      {type === "youtube-link" && <YoutubeLinkContent slot={slot} />}
      {type === "ispring-html" && <IspringHTMLCreateContent slot={slot} />}
    </div>
  );
};
