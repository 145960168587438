import React from "react";
import { CButton, CTooltip } from "@coreui/react";
import { Customer, Student } from "../../../store";
import CIcon from "@coreui/icons-react";
const JSONToCSV = require("json2csv").parse;

export interface ExportStudentsProps {
  selected_students: string[];
  students: Student[] | null;
  courses: any;
  customer: Customer;
}

const ExportStudents: React.FC<ExportStudentsProps> = ({
  selected_students,
  courses,
  customer,
  students,
}) => {
  const getStudentData = (selected_students: string[]) => {
    if (selected_students.length === 0) {
      return window.alert("You need to select students to export.");
    }
    let studentsToExport = [];
    let fieldsArray = [
      "Student Name",
      "Business Name",
      "Student Email",
      "Student ID",
      "Group",
      "School Id",
    ];

    for (let i = 0; i < students!.length; i++) {
      if (selected_students.includes(students![i]._id)) {
        let studentExport: any = {
          "Student Name":
            students![i].first_name || students![i].last_name
              ? `${students![i].first_name} ${students![i].last_name}`
              : "N/A",
          "Business Name": customer.business,
          "Student Email": students![i].email ? students![i].email : "N/A",
          "Student ID": students![i]._id ? students![i]._id : "N/A",
          Group: students![i].group ? students![i].group : "N/A",
          "School Id": customer._id,
        };

        //Add grades to the CSV Export
        students![i].grades.forEach((grade: any) => {
          let courseInfo = courses.find((c: any) => c._id === grade.course);
          if (courseInfo) {
            studentExport[courseInfo.courseTitle] = `${(
              grade.grade * 100
            ).toFixed()}%`;
            if (!fieldsArray.includes(courseInfo.courseTitle)) {
              fieldsArray.push(courseInfo.courseTitle);
            }
          }
        });

        studentsToExport.push(studentExport);
      }
    }
    saveCsv(studentsToExport, fieldsArray);
  };

  const saveCsv = async (students: any, fieldsArray: any) => {
    const csv = JSONToCSV(students, { fields: fieldsArray });

    const link = document.createElement("a");
    link.href = "data:text/csv," + encodeURIComponent(csv);
    link.download = `students_${new Date()}.csv`;
    link.click();
  };

  return (
    <div>
      <CTooltip content="Export Students">
        <CButton
          color="primary"
          size="md"
          className="ml-2"
          onClick={() => getStudentData(selected_students)}
        >
          <CIcon name="cil-cloud-download" />
        </CButton>
      </CTooltip>
    </div>
  );
};

export default ExportStudents;
