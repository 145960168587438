import { useContext, useEffect } from "react";
import Template3 from "./templates/Template_3";
import Template2 from "./templates/Template_2";
import Template1 from "./templates/Template_1";
import TemplateVideo from "./templates/TemplateVideo";
import TemplateQuiz from "./templates/TemplateQuiz";
import { TakeCourseContext } from "../../TakeCourseContext";
import Loading from "../../../../components/Loading";
import { hasWatchedAllVideosOfModule } from "./templates/helpers/hasWatchedAllVideosOfmodule";
import { hasAnsweredAllQuizQuestionsOfModule } from "./templates/helpers/hasAnsweredAllQuizQuestionsOfModule";

export default function CourseContent() {
  const {
    selectedModule,
    setAllowedNext,
    setCourseProgress,
    module_id,
    courseProgress,
  } = useContext(TakeCourseContext);

  useEffect(() => {
    setAllowedNext(
      hasWatchedAllVideosOfModule(selectedModule, courseProgress, module_id) &&
        hasAnsweredAllQuizQuestionsOfModule(
          selectedModule,
          module_id,
          courseProgress
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModule, courseProgress]);

  if (!selectedModule || !courseProgress) {
    return <Loading />;
  }

  return (
    <div id="courseContent" className="d-flex w-100 h-100" style={{}}>
      {selectedModule.moduleLayout === "layout3" && <Template3 />}
      {selectedModule.moduleLayout === "layout2" && <Template2 />}
      {selectedModule.moduleLayout === "layout-vidText" && <Template1 />}
      {selectedModule.moduleLayout === "layout-vid" && <TemplateVideo />}
      {selectedModule.moduleLayout === "quiz" && (
        <TemplateQuiz
          setAllowedNext={setAllowedNext}
          selectedModule={selectedModule}
          setCourseProgress={setCourseProgress}
          courseProgress={courseProgress}
          module_id={module_id}
        />
      )}
    </div>
  );
}
