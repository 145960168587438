import { CButton } from "@coreui/react";
import React, { useState, useEffect, useContext } from "react";
import { TakeCourseContext } from "../../../TakeCourseContext";
import QuizMultipleChoice from "./QuizMultipleChoice";

export default function TemplateQuiz(props: any) {
  const { selectedModule, setAllowedNext } = props;
  const { courseProgress, module_id } = useContext(TakeCourseContext);
  const [quiz, setQuiz] = useState<any>([]);
  const [displayId, setDisplayId] = useState("");

  useEffect(() => {
    if (courseProgress.course_progress?.[module_id]?.[quiz.id]) {
      const questionsAnswered = Object.keys(
        courseProgress.course_progress[module_id]?.[quiz.id]
      ).length;

      if (questionsAnswered !== quiz.length) {
        setAllowedNext(false);
      }
    }
  }, [courseProgress, setAllowedNext, module_id, quiz]);

  useEffect(() => {
    setQuiz(selectedModule.quizContent);
  }, [selectedModule]);

  const setQuestionDisplay = (id: any) => {
    setDisplayId(id);
  };

  const nextQuestion = (id: any) => {
    let indexOfCurrQues = quiz.findIndex((ques: any) => ques.id === id);
    if (indexOfCurrQues !== quiz.length - 1) {
      let idOfNextQues = quiz[indexOfCurrQues + 1].id;
      setDisplayId(idOfNextQues);
    }
  };

  const previousQuestion = (id: any) => {
    let indexOfCurrQues = quiz.findIndex((ques: any) => ques.id === id);
    if (indexOfCurrQues !== 0) {
      let idOfNextQues = quiz[indexOfCurrQues - 1].id;
      setDisplayId(idOfNextQues);
    }
  };

  return (
    <div
      id="template_quiz"
      className="d-flex flex-column"
      style={{
        height: "100%",
        width: "100%",
        borderRadius: "10px",
      }}
    >
      {quiz
        .map((question: any) => {
          return (
            <div
              key={question.id}
              className="flex-column align-items-end w-100 h-100"
            >
              <div className="d-flex h-100 w-100">
                {question.id === displayId && (
                  <QuizMultipleChoice
                    question={question}
                    setQuestionDisplay={setQuestionDisplay}
                    displayId={displayId}
                    quizId={selectedModule.id}
                    nextQuestion={nextQuestion}
                    previousQuestion={previousQuestion}
                  />
                )}
              </div>
            </div>
          );
        })
        .reverse()}
      {!displayId && (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            background: "linear-gradient(0.375turn, #1b2c5f, #0562A5)",
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            overflow: "auto",
            minHeight: "400px",
          }}
        >
          <h2>Ready for a Quiz?</h2>
          <CButton
            color="primary"
            size="md"
            onClick={() => setDisplayId(quiz[0].id)}
          >
            Start Quiz
          </CButton>
        </div>
      )}
    </div>
  );
}
