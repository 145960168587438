import { useState, useEffect, useCallback, useRef } from "react";
import { Get_API } from "../../../API/Get_API";
import { Delete_API } from "../../../API/Delete_API";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { CDataTable, CInput, CInputCheckbox, CContainer } from "@coreui/react";
import { Customer, IState } from "../../../store";
import CustomerModal from "./CustomerModal";
import styled from "styled-components";
import { Field, Formik, FormikProps } from "formik";
import ExportCustomers from "./ExportCustomers";
import ConfirmModal from "../../../components/ConfirmModal";

export default function CustomersList() {
  const { getAccessTokenSilently } = useAuth0();
  const [query, setQuery] = useState("");
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [viewing, setViewing] = useState<Customer[]>([]);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const customers = useSelector((state: IState) => state.customers);
  const formRef = useRef<FormikProps<any>>(null);

  const getCustomers = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const customers = await Get_API(`/api/customers`, token);
    if (customers.success) {
      dispatch({ type: "set", customers: customers.success });
    }
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  const fields = [
    "business",
    "first_name",
    "last_name",
    "email",
    {
      key: "select",
      label: (
        <span
          onClick={() => {
            if (
              formRef.current?.values.selected_customers.length !==
              viewing?.length
            ) {
              let ids = viewing.map((custom) => {
                return custom._id;
              });
              formRef.current!.setFieldValue("selected_customers", ids);
            } else {
              formRef.current!.setFieldValue("selected_customers", []);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          Select
        </span>
      ),
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const handleCustomerDeSelect = () => {
    setCustomer(null);
    setModal(!modal);
  };

  const handleDelete = async (customersToDelete: any, form: any) => {
    let token = await getAccessTokenSilently();
    let deleteCustomers = await Delete_API(
      "/api/customers",
      { customersToDelete },
      token
    );
    if (deleteCustomers.success) {
      form.resetForm();
      getCustomers();
    }
  };

  return (
    <>
      <PageTitle>Customers</PageTitle>
      <StyledSearchContainer>
        <FullWidthSearch onChange={(e: any) => setQuery(e.target.value)} />
      </StyledSearchContainer>
      <Formik
        onSubmit={(values, form) => {
          handleDelete(values, form);
        }}
        initialValues={{ selected_customers: [] }}
        innerRef={formRef}
      >
        {(form: FormikProps<any>) => (
          <>
            <CContainer className="table_container">
              <CDataTable
                items={customers ? customers : []}
                fields={fields}
                hover
                sorter
                clickableRows
                pagination
                itemsPerPage={6}
                tableFilterValue={query}
                loading={!customers}
                onFilteredItemsChange={(items: Customer[]) => setViewing(items)}
                scopedSlots={{
                  business: (customer: Customer) => (
                    <td
                      onClick={() => {
                        setCustomer(customer);
                        toggle();
                      }}
                    >
                      {customer.business}
                    </td>
                  ),
                  first_name: (customer: Customer) => (
                    <td
                      onClick={() => {
                        setCustomer(customer);
                        toggle();
                      }}
                    >
                      {customer.first_name}
                    </td>
                  ),
                  last_name: (customer: Customer) => (
                    <td
                      onClick={() => {
                        setCustomer(customer);
                        toggle();
                      }}
                    >
                      {customer.last_name}
                    </td>
                  ),
                  email: (customer: Customer) => (
                    <td
                      onClick={() => {
                        setCustomer(customer);
                        toggle();
                      }}
                    >
                      {customer.email}
                    </td>
                  ),
                  select: (customer: Customer) => (
                    <td className="d-flex justify-content-center">
                      <Field
                        name="selected_customers"
                        checked={form.values.selected_customers.includes(
                          customer._id
                        )}
                        value={customer._id}
                        as={CInputCheckbox}
                      />
                    </td>
                  ),
                }}
              />
            </CContainer>
            <CContainer className="d-flex justify-content-between p-0">
              <ConfirmModal
                title="Delete User(s)"
                icon="cil-trash"
                buttonText={`Delete`}
                query={`Are you sure you want to delete the ${formRef.current?.values.selected_customers.length} user(s)?`}
                buttonColor="danger"
                onConfirm={form.handleSubmit}
                buttonClassname="mr-3"
                disabled={
                  formRef.current?.values.selected_customers.length === 0
                }
              />
              <div className="d-flex">
                <ExportCustomers
                  selected_customers={form.values.selected_customers}
                  customers={customers ? customers : []}
                />
                <CustomerModal
                  customer={customer}
                  toggle={toggle}
                  modal={modal}
                  customers={customers ? customers : []}
                  handleCustomerDeSelect={handleCustomerDeSelect}
                />
              </div>
            </CContainer>
          </>
        )}
      </Formik>
    </>
  );
}

export const StyledSearchContainer = styled(CContainer)`
  padding: 0;
`;

export const FullWidthSearch = styled(CInput)`
  width: 100%;
  margin: 1rem auto;
`;

const PageTitle = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 3em;
`;
