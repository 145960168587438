import { ContentEditor } from "./ContentEditor";
import { useContext } from "react";
import { CourseContext } from "../../CourseContext";

export default function Template1() {
  const { module } = useContext(CourseContext);

  return (
    <div
      className="d-flex flex-column"
      style={{
        backgroundColor: "#5AB3F7",
        height: "100%",
        width: "100%",
        borderRadius: "20px",
        overflow: "auto",
      }}
    >
      <div
        className="d-flex align-items-center justify-content-center w-100"
        style={{
          backgroundColor:
            module.moduleContent1 === "text" || module.moduleContent1 === ""
              ? "#0278CF"
              : "",
          borderRadius: "3px",
          overflow: "hidden",
          borderBottom: "2px solid #399BE8",
          height: "60%",
        }}
      >
        <ContentEditor layoutSlot={"moduleContent1"} />
      </div>

      <div
        className="d-flex flex-column align-items-center justify-content-center w-100"
        style={{
          backgroundColor:
            module.moduleContent2 === "text" || module.moduleContent2 === ""
              ? "#0278CF"
              : "",
          height: "40%",
          borderRadius: "3px",
          borderTop: "2px solid #399BE8",
          overflow: "hidden",
        }}
      >
        <ContentEditor layoutSlot={"moduleContent2"} />
      </div>
    </div>
  );
}
