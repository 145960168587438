import {
  CButton,
  CDataTable,
  CImg,
  CModal,
  CModalBody,
  CModalFooter,
  CInput,
  CModalHeader,
  CContainer,
  CInputCheckbox,
  CTooltip,
} from "@coreui/react";
import { FC, useState, useRef } from "react";
import { Formik, FormikProps, Field } from "formik";
import styled from "styled-components";
import { UploadCollapse } from "./details/upload-collapse";
import CIcon from "@coreui/icons-react";
import { CropperCollapse } from "./details/cropper-collapse";
import moment from "moment";

export interface Media {
  link: string;
  type: "image" | "video" | "ispring-html";
  _id: string;
  created?: Date;
  title?: string;
}

export interface MediaModalProps {
  useTrigger?: boolean;
  media: Media[];
  handleMediaSelect: (v: string) => void;
  handleDelete?: (v: any) => void;
  handleUpload: (v: any) => void;
  fileType?: string;
}

export const MediaModal: FC<MediaModalProps> = ({
  media,
  handleMediaSelect,
  useTrigger,
  handleDelete,
  handleUpload,
  fileType,
}) => {
  const formRef = useRef<FormikProps<any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [showCollapse, setShowCollapse] = useState(false);
  const [query, setQuery] = useState("");
  const [viewing, setViewing] = useState<Media[]>([]);
  const [showCropper, setShowCropper] = useState<number[]>([]);

  const toggle = () => {
    setShowModal(!showModal);
  };
  const toggleCollapse = () => {
    setShowCollapse(!showCollapse);
  };
  const toggleCropperCollapse = (index: number) => {
    const position = showCropper.indexOf(index);
    let newDetails = showCropper.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...showCropper, index];
    }
    setShowCropper(newDetails);
  };

  const fields = [
    { key: "link", label: "Media", _style: { width: "20%" } },
    "title",
    "created",
    {
      key: "select",
      label: (
        <span
          onClick={() => {
            if (
              formRef.current?.values.selected_media.length !== viewing?.length
            ) {
              let ids = viewing.map((custom) => {
                return custom._id;
              });
              formRef.current!.setFieldValue("selected_media", ids);
            } else {
              formRef.current!.setFieldValue("selected_media", []);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          Select
        </span>
      ),
    },
    {
      key: "show_details",
      label: "",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];

  return (
    <>
      {useTrigger && (
        <CButton size="md" color="primary" className="w-100" onClick={toggle}>
          Media Library
        </CButton>
      )}
      <Formik
        onSubmit={(values) => {
          handleDelete && handleDelete(values);
        }}
        initialValues={{ selected_media: [] }}
        innerRef={formRef}
      >
        {(form: FormikProps<any>) => (
          <CModal
            show={!useTrigger ? true : showModal}
            onClose={toggle}
            size="xl"
            style={{ height: "90vh" }}
          >
            <CModalHeader>
              <StyledSearchContainer>
                <h3>Media Library</h3>
                <FullWidthSearch
                  placeholder="Search by Title"
                  onChange={(e: any) => setQuery(e.target.value)}
                />
              </StyledSearchContainer>
            </CModalHeader>
            <CModalBody style={{ overflow: "auto" }}>
              <CDataTable
                responsive={false}
                items={media}
                fields={fields}
                tableFilterValue={query}
                onFilteredItemsChange={(items: Media[]) => setViewing(items)}
                clickableRows
                itemsPerPage={5}
                pagination
                hover
                scopedSlots={{
                  link: (media: Media) => (
                    <td onClick={() => handleMediaSelect(media.link)}>
                      {media.type === "image" ? (
                        <CImg src={media.link} width="100%" />
                      ) : media.type === "video" ? (
                        <video
                          width="100%"
                          src={media.link}
                          style={{
                            borderTopRightRadius: "5px",
                            borderTopLeftRadius: "5px",
                            cursor: "pointer",
                          }}
                          controls
                        />
                      ) : (
                        "ISpring Course"
                      )}
                    </td>
                  ),
                  title: (media: Media) => (
                    <td onClick={() => handleMediaSelect(media.link)}>
                      {media.title}
                    </td>
                  ),
                  created: (media: Media) => {
                    return (
                      <td onClick={() => handleMediaSelect(media.link)}>
                        {moment(media.created).parseZone().format("MM/DD/YYYY")}
                      </td>
                    );
                  },
                  select: (media: Media) => (
                    <td className="d-flex justify-content-center">
                      <Field
                        name="selected_media"
                        style={{ marginLeft: 0 }}
                        checked={form.values.selected_media.includes(media._id)}
                        value={media._id}
                        as={CInputCheckbox}
                      />
                    </td>
                  ),
                  show_details: (item: Media, index: number) => {
                    return (
                      <td className="py-2">
                        <CTooltip
                          content={
                            item.type === "image"
                              ? "Click here to crop your image to the size of the selected container."
                              : "You can only edit images."
                          }
                        >
                          <CButton
                            color="secondary"
                            variant="outline"
                            shape="square"
                            disabled={item.type !== "image"}
                            size="sm"
                            onClick={() => {
                              toggleCropperCollapse(index);
                            }}
                          >
                            {showCropper.includes(index)
                              ? "Hide"
                              : "Edit Image"}
                          </CButton>
                        </CTooltip>
                      </td>
                    );
                  },
                  details: (item: Media, index: number) => {
                    return (
                      <CropperCollapse
                        inputImg={item.link}
                        showCropper={showCropper.includes(index)}
                        showModal={showModal}
                        handleUpload={handleUpload}
                      />
                    );
                  },
                }}
              />
            </CModalBody>
            <CModalFooter>
              <UploadCollapse
                handleUpload={(v) => handleUpload(v)}
                showCollapse={showCollapse}
                fileType={fileType}
              />
              {handleDelete && (
                <CButton
                  color="danger"
                  onClick={form.handleSubmit}
                  disabled={!form.values.selected_media.length}
                >
                  Delete Media
                </CButton>
              )}
              <CTooltip
                content={
                  showCollapse ? "Hide upload options." : "Show upload options."
                }
              >
                <CButton
                  color={showCollapse ? "primary" : "success"}
                  size="md"
                  onClick={toggleCollapse}
                >
                  {showCollapse ? (
                    <CIcon name="cil-minus" />
                  ) : (
                    <CIcon name="cil-plus" />
                  )}
                </CButton>
              </CTooltip>
            </CModalFooter>
          </CModal>
        )}
      </Formik>
    </>
  );
};

export const FullWidthSearch = styled(CInput)`
  width: 100%;
  margin: 1rem auto;
  text-align: center;

  &::placeholder {
    color: black !important;
    text-align: center;
  }
`;

export const StyledSearchContainer = styled(CContainer)`
  padding: 0;
`;
