import React, { useContext } from "react";
import { CInput } from "@coreui/react";
import { CourseContext } from "../CourseContext";

const CourseTitle: React.FC = () => {
  const updateTitle = (newTitle: string) => {
    course.course_title = newTitle;
    setCourse(course);
  };
  const { course, setCourse } = useContext(CourseContext);

  return (
    <div className="d-flex flex-row w-100 align-items-center justify-content-center mt-2">
      <CInput
        onChange={(e: any) => updateTitle(e.target.value)}
        type="text"
        defaultValue={course.course_title}
        style={{
          height: "60px",
          textAlign: "center",
          fontSize: "2em",
          backgroundColor: "#5282A3",
          color: "white",
          border: "none",
          borderRadius: "10px",
          width: "100%",
        }}
      />
    </div>
  );
};

export default CourseTitle;
