import IframeResizer from "iframe-resizer-react";
import { FC } from "react";

export interface IspringHTMLContentProps {
  content: string;
}

export const IspringHTMLContent: FC<IspringHTMLContentProps> = ({
  content,
}) => {
  return (
    <div
      className="d-flex w-100 h-100 justify-content-center align-items-center m-2"
      style={{
        color: "white",
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "56.25%",
      }}
    >
      <IframeResizer
        frameBorder="0"
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderRadius: "3px",
          backgroundColor: "transparent",
        }}
        src={`${process.env.REACT_APP_S3_ROOT_URL}${content}/index.html`}
        title="Ispring Course"
      />
    </div>
  );
};
