import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { CButton } from "@coreui/react";
import { TakeCourseContext } from "../TakeCourseContext";
import CIcon from "@coreui/icons-react";

const CourseNavigationBottom: React.FC = () => {
  const history = useHistory();
  const {
    nextModule,
    prevModule,
    toggleAside,
    allowedNext,
    module_id,
    course,
    courseProgress,
    saveProgress,
    course_id,
  } = useContext(TakeCourseContext);

  const finishCourse = () => {
    let numberOfQuestions: number = 0;
    let numberOfCorrectAnswers: number = 0;

    let modulesArray = Object.keys(courseProgress.course_progress);

    for (const mod of modulesArray) {
      const quizzes = Object.keys(courseProgress.course_progress[mod]);
      console.log(quizzes);
      if (quizzes[0] !== "watched" && quizzes[0] !== undefined) {
        const questionIds = Object.keys(
          courseProgress.course_progress[mod][quizzes[0]]
        );
        // eslint-disable-next-line no-loop-func
        questionIds.forEach((qId) => {
          numberOfQuestions += 1;
          let question = courseProgress.course_progress[mod][quizzes[0]][qId];
          if (question.answer === question.choice) {
            numberOfCorrectAnswers += 1;
          }
        });
      }
    }

    const finalGrade =
      numberOfQuestions > 0 ? numberOfCorrectAnswers / numberOfQuestions : 1;
    let finalProgress = { ...courseProgress, finalGrade };
    saveProgress(finalProgress);
    history.push(`/course/grade/${course_id}/`);
  };

  return (
    <div className="d-flex w-100 flex-row justify-content-between">
      <CButton
        onClick={prevModule}
        size="md"
        alt="previous module"
        className="text-light my-3 w-100"
        color="warning"
      >
        <CIcon size="lg" name="cil-arrow-left" />
      </CButton>
      <CButton color="primary" className="my-3 mx-2" onClick={toggleAside}>
        <CIcon size="lg" name="cil-hamburger-menu" />
      </CButton>
      {course?.modules[course?.modules.length - 1].id !== module_id && (
        <CButton
          onClick={nextModule}
          size="md"
          disabled={!allowedNext}
          alt="Next module"
          className="text-light my-3 w-100"
          color="warning"
        >
          <CIcon size="lg" name="cil-arrow-right" />
        </CButton>
      )}
      {course?.modules[course?.modules.length - 1].id === module_id && (
        <CButton
          onClick={finishCourse}
          size="md"
          disabled={
            !allowedNext ||
            Object.keys(courseProgress?.course_progress ?? {})?.length <
              course.modules.length
          }
          style={{ fontSize: "1.1rem" }}
          className="text-light my-3 w-100 h-100"
          color="success"
        >
          Finish
        </CButton>
      )}
    </div>
  );
};

export default CourseNavigationBottom;
