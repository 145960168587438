import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Get_API } from "../../API/Get_API";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IState } from "../../store";
import CompChart from "./CompChart";
// import CompChart from "./CompChart";

export interface CustomerDashProps {}

export interface DashParamTypes {
  customer_id: string;
}

const CustomerDash: React.FC<CustomerDashProps> = (props) => {
  const [coursesCompleted] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();
  const { customer_id } = useParams<DashParamTypes>();
  const students = useSelector((state: IState) => state.students);
  const customerCourses = useSelector((state: IState) => state.courses);

  const getStudents = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const students = await Get_API(
      `/api/students?customer_id=${customer_id}`,
      token
    );
    if (students.success) {
      dispatch({ type: "set", students: students.success });
    }
  }, [dispatch, getAccessTokenSilently, customer_id]);

  const getCustomer = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const customer = await Get_API(
      `/api/customers?customer_id=${customer_id}`,
      token
    );
    if (customer.success) {
      if (user?.email === customer.success[0].email) {
        dispatch({ type: "set", customers: customer.success });
      }
    }
  }, [dispatch, getAccessTokenSilently, customer_id, user?.email]);

  const getCourses = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const courses = await Get_API(
      `/api/courses?customer_id=${customer_id}`,
      token
    );
    if (courses.success) {
      dispatch({ type: "set", courses: courses.success });
    }
  }, [dispatch, getAccessTokenSilently, customer_id]);

  useEffect(() => {
    if (user?.email) {
      getStudents();
      getCustomer();
      getCourses();
    }
  }, [getStudents, getCustomer, user?.email, getCourses]);

  // useEffect(() => {
  //   let numOfCourses = 0;
  //   students?.forEach((stud) => {
  //     stud.grades.forEach((grade) => (numOfCourses += 1));
  //   });
  //   setCoursesCompleted(numOfCourses);
  // }, [students]);

  return (
    <div className="customerDashContent d-flex w-100 justify-content-center p-3 h-100">
      <div className="d-flex flex-column w-100 h-100">
        <CompChart
          customerCourses={customerCourses}
          students={students}
          setSelectedGroup={setSelectedGroup}
        />
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center w-lg-25 w-sm-100">
        <div
          className="customerBoxes"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            color: "black",
          }}
        >
          <h1 className="m-0 text-center w-100">
            {selectedGroup ? selectedGroup.groupTotal : students?.length}
          </h1>
          <span className="text-center w-100">
            {selectedGroup
              ? `Students In ${selectedGroup.groupName}`
              : "Total Students"}
          </span>
        </div>
        <div
          className="customerBoxes"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            color: "black",
          }}
        >
          {!selectedGroup ? (
            <div className="d-flex flex-column w-100 text-center">
              <h1 className="m-0 text-center w-100" style={{ color: "black" }}>
                {coursesCompleted}
              </h1>
              <span className="text-center w-100" style={{ color: "black" }}>
                {coursesCompleted === 1
                  ? "Course Completed"
                  : "Courses Completed"}
              </span>
            </div>
          ) : (
            <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
              <span className="m-0 text-center w-100">
                In Progress: {selectedGroup.inProgress}
              </span>
              <span className="m-0 text-center w-100 ">
                Failed: {selectedGroup.failed}
              </span>
              <span className="m-0 text-center w-100 ">
                Passed: {selectedGroup.passed}
              </span>
            </div>
          )}
        </div>
        <div
          className="customerBoxes"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            color: "black",
          }}
        >
          {!selectedGroup ? (
            <div className="d-flex flex-column w-100 h-100 text-center justify-content-center align-items-center">
              <h1 className="m-0 text-center w-100">
                {customerCourses?.length ?? 0}
              </h1>
              <span className="text-center w-100">
                {" "}
                {selectedGroup
                  ? selectedGroup.groupName
                  : customerCourses?.length === 1
                  ? "Class Available"
                  : "Classes Available"}
              </span>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
              {console.log(selectedGroup)}
              <span className="m-0 text-center w-100">
                In Progress: {selectedGroup.percentInProgress.toFixed()}%
              </span>
              <span className="m-0 text-center w-100">
                Failed: {selectedGroup.percentFailed.toFixed()}%
              </span>
              <span className="m-0 text-center w-100">
                Passed: {selectedGroup.percentPassed.toFixed()}%
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerDash;
