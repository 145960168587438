import React from "react";
import { CButton, CTooltip } from "@coreui/react";
import { Customer } from "../../../store";
import CIcon from "@coreui/icons-react";
const JSONToCSV = require("json2csv").parse;

export interface ExportCustomerProps {
  selected_customers: string[];
  customers: Customer[];
}

const ExportCustomers: React.FC<ExportCustomerProps> = ({
  selected_customers,
  customers,
}) => {
  const getCustomerData = async () => {
    if (selected_customers.length > 0) {
      let formatted = [];

      for (let i = 0; i < customers.length; i++) {
        if (selected_customers.includes(customers[i]._id)) {
          try {
            let customerExport: any = {
              "Customer Name":
                customers[i].first_name || customers[i].last_name
                  ? `${customers[i].first_name} ${customers[i].last_name}`
                  : "N/A",
              "Business Name": customers[i].business
                ? customers[i].business
                : "N/A",
              "Customer Email": customers[i].email ? customers[i].email : "N/A",
              "Customer ID": customers[i]._id ? customers[i]._id : "N/A",
              "Student Count": customers[i].student
                ? customers[i].student.length
                : 0,
              "Admin Count": customers[i].admin ? customers[i].admin.length : 0,
              "Courses Count": customers[i].courses
                ? customers[i].courses.length
                : 0,
            };
            formatted.push(customerExport);
          } catch (error) {
            console.log(error);
          }
        }
      }
      saveCsv(formatted);
    } else {
      window.alert("Uh Oh! You have not selected any customers.");
    }
  };

  const saveCsv = async (customers: any) => {
    console.log(customers);
    const csv = JSONToCSV(customers, {
      fields: [
        "Customer Name",
        "Business Name",
        "Customer Email",
        "Customer ID",
        "School ID",
        "Student Count",
        "Admin Count",
        "Courses Count",
      ],
    });

    const link = document.createElement("a");
    link.href = "data:text/csv," + encodeURIComponent(csv);
    link.download = `customers_${new Date()}.csv`;
    link.click();
  };

  return (
    <div>
      <CTooltip content="Export Customers">
        <CButton
          color="primary"
          alt="export list"
          size="md"
          onClick={getCustomerData}
          disabled={!customers}
        >
          <CIcon name="cil-cloud-download" />
        </CButton>
      </CTooltip>
    </div>
  );
};

export default ExportCustomers;
