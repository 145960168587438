import { useState, useEffect, useCallback, useRef } from "react";
import { Get_API } from "../../../API/Get_API";
import { Delete_API } from "../../../API/Delete_API";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { CDataTable, CInput, CInputCheckbox, CContainer } from "@coreui/react";
import { Admin, IState } from "../../../store";
import AdminsModal from "./AdminsModal";
import styled from "styled-components";
import { Field, Formik, FormikProps } from "formik";
import ConfirmModal from "../../../components/ConfirmModal";

export default function AdminsList() {
  const { getAccessTokenSilently } = useAuth0();
  const [query, setQuery] = useState("");
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [viewing, setViewing] = useState<Admin[]>([]);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const admins = useSelector((state: IState) => state.admins);
  const formRef = useRef<FormikProps<any>>(null);

  const getAdmins = useCallback(async () => {
    const token = await getAccessTokenSilently();
    const admins = await Get_API(`/api/admins`, token);
    if (admins.success) {
      dispatch({ type: "set", admins: admins.success });
    }
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (!admins) {
      getAdmins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAdmins]);

  const fields = [
    "first_name",
    "last_name",
    "email",
    {
      key: "select",
      label: (
        <span
          onClick={() => {
            if (
              formRef.current?.values.selected_admins.length !== viewing?.length
            ) {
              let ids = viewing.map((custom) => {
                return custom._id;
              });
              formRef.current!.setFieldValue("selected_admins", ids);
            } else {
              formRef.current!.setFieldValue("selected_admins", []);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          Select
        </span>
      ),
    },
  ];

  const toggle = () => {
    setModal(!modal);
  };

  const handleAdminDeSelect = () => {
    setAdmin(null);
    setModal(!modal);
  };

  const handleDelete = async (adminsToDelete: any, form: any) => {
    let token = await getAccessTokenSilently();
    let deleteAdmins = await Delete_API(
      "/api/admins",
      { adminsToDelete },
      token
    );
    if (deleteAdmins.success) {
      form.resetForm();
      getAdmins();
    }
  };

  return (
    <>
      <PageTitle>Admins</PageTitle>
      <StyledSearchContainer>
        <FullWidthSearch onChange={(e: any) => setQuery(e.target.value)} />
      </StyledSearchContainer>
      <Formik
        onSubmit={(values, form) => {
          handleDelete(values, form);
        }}
        initialValues={{ selected_admins: [] }}
        innerRef={formRef}
      >
        {(form: FormikProps<any>) => (
          <>
            <CContainer className="table_container">
              <CDataTable
                items={admins ? admins : []}
                fields={fields}
                hover
                sorter
                clickableRows
                pagination
                itemsPerPage={6}
                tableFilterValue={query}
                loading={!admins}
                onFilteredItemsChange={(items: Admin[]) => setViewing(items)}
                scopedSlots={{
                  first_name: (admin: Admin) => (
                    <td
                      onClick={() => {
                        setAdmin(admin);
                        toggle();
                      }}
                    >
                      {admin.first_name}
                    </td>
                  ),
                  last_name: (admin: Admin) => (
                    <td
                      onClick={() => {
                        setAdmin(admin);
                        toggle();
                      }}
                    >
                      {admin.last_name}
                    </td>
                  ),
                  email: (admin: Admin) => (
                    <td
                      onClick={() => {
                        setAdmin(admin);
                        toggle();
                      }}
                    >
                      {admin.email}
                    </td>
                  ),
                  select: (admin: Admin) => (
                    <td className="d-flex justify-content-center">
                      <Field
                        name="selected_admins"
                        checked={form.values.selected_admins.includes(
                          admin._id
                        )}
                        value={admin._id}
                        as={CInputCheckbox}
                      />
                    </td>
                  ),
                }}
              />
            </CContainer>
            <CContainer className="d-flex justify-content-between p-0">
              <ConfirmModal
                title="Delete User(s)"
                icon="cil-trash"
                buttonText={`Delete`}
                query={`Are you sure you want to delete the ${formRef.current?.values.selected_admins.length} user(s)?`}
                buttonColor="danger"
                onConfirm={form.handleSubmit}
                buttonClassname="mr-3"
                disabled={formRef.current?.values.selected_admins.length === 0}
              />
              <div className="d-flex">
                <AdminsModal
                  admin={admin}
                  toggle={toggle}
                  modal={modal}
                  admins={admins ? admins : []}
                  handleAdminDeSelect={handleAdminDeSelect}
                />
              </div>
            </CContainer>
          </>
        )}
      </Formik>
    </>
  );
}

export const StyledSearchContainer = styled(CContainer)`
  padding: 0;
`;

export const FullWidthSearch = styled(CInput)`
  width: 100%;
  margin: 1rem auto;
`;

const PageTitle = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 3em;
`;
