import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardText,
} from "@coreui/react";
import { FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const InvalidUser: FC = () => {
  const { logout } = useAuth0();

  return (
    <div
      className="d-flex justify-content-center align-items-center text-light"
      style={{ width: "100vw", height: "100vh", backgroundColor: "#00194a" }}
    >
      <CCard color="primary" className="w-50">
        <CCardHeader>Invalid User</CCardHeader>
        <CCardBody>
          <CCardText>
            Please contact your school in order to gain access to the Crisis
            Champion App.
          </CCardText>
        </CCardBody>
        <CCardFooter color="primary">
          <CButton color="secondary" className="text-light" onClick={logout}>
            Logout
          </CButton>
        </CCardFooter>
      </CCard>
    </div>
  );
};
