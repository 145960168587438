import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { CButton, CInput } from "@coreui/react";
import { Course } from "../../../../../store";
import { CourseContext } from "../CourseContext";

export interface QuizOptions {
  "answer-a": string;
  "answer-b": string;
  "answer-c": string;
  "answer-d": string;
}

export interface QuizContent {
  quizType: string;
  id: string;
  content: string;
  answer: string;
  options: QuizOptions;
}

export interface CourseModule {
  title: string;
  id: string;
  moduleType: string;
  moduleLayout: string;
  moduleContent1: any;
  moduleContent2: any;
  moduleContent3: any;
  quizContent: QuizContent[];
}

export interface CourseModulesProps {
  course: Course;
  setCourse: (course: Course) => void;
}

const CourseModules: React.FC = () => {
  const { course, setCourse, moduleSelected, setModuleSelected, setDisplayId } =
    useContext(CourseContext);

  const createNewModule = () => {
    const newModule: CourseModule = {
      title: `Section ${course.modules.length + 1}`,
      id: uuidv4(),
      moduleType: "textAndPicture",
      moduleLayout: "layout3",
      moduleContent1: "",
      moduleContent2: "",
      moduleContent3: "",
      quizContent: [
        {
          quizType: "multipleChoice",
          id: uuidv4(),
          content: "",
          answer: "answer-a",
          options: {
            "answer-a": "True",
            "answer-b": "False",
            "answer-c": "Sometimes",
            "answer-d": "Never",
          },
        },
      ],
    };

    course.modules = [...course.modules, newModule];
    setCourse(course);
  };

  const deleteModule = (id: string) => {
    let r = window.confirm("Delete this module?");
    if (r) {
      if (course.modules.length > 1) {
        course.modules = course.modules.filter(
          (mod: CourseModule) => mod.id !== id
        );
        setModuleSelected(course.modules[0].id);
        setCourse(course);
      } else {
        window.alert("You must have at least one module in a course.");
      }
    }
  };

  const updateSectionTitle = (id: string, newTitle: string) => {
    let updator = { ...course };
    updator.modules.filter((mod: CourseModule) => mod.id === id)[0].title =
      newTitle;

    setCourse({ ...updator });
  };

  const moveDown = (id: string) => {
    let updator = { ...course };
    let indexOfModule = updator.modules.findIndex(
      (mod: CourseModule) => mod.id === id
    );

    let moduleToMove = updator.modules.filter(
      (mod: CourseModule) => mod.id === id
    )[0];

    updator.modules.splice(indexOfModule, 1);
    updator.modules.splice(indexOfModule + 1, 0, moduleToMove);

    setCourse({
      ...updator,
    });
  };

  return (
    <div className="d-flex flex-column align-items-start align-self-center mr-2">
      <h5 className="text-light w-100 text-center">Class Sections</h5>
      {course.modules.map((module: CourseModule) => {
        return (
          <div key={module.id} className="d-flex flex-row my-2">
            <div
              className="d-flex flex-row w-100 "
              style={{
                backgroundColor: "#399BE8",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                border:
                  moduleSelected === module.id
                    ? "2px solid rgb(137, 181, 221)"
                    : "",
              }}
            >
              <CButton
                size="sm"
                color="primary"
                className="w-25"
                disabled={course.modules.length === 1}
                style={{
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                }}
                onClick={() => moveDown(module.id)}
              >
                ↓
              </CButton>
              <CInput
                type="text"
                style={{
                  backgroundColor: "#399BE8",
                  width: "75%",
                  border: "none",
                  color: "white",
                }}
                defaultValue={module.title}
                onClick={() => {
                  setDisplayId(module.quizContent[0].id);
                  setModuleSelected(module.id);
                }}
                onChange={(e: any) =>
                  updateSectionTitle(module.id, e.target.value)
                }
              />
            </div>
            <div>
              <CButton
                onClick={() => {
                  deleteModule(module.id);
                }}
                disabled={course.modules.length === 1}
                size="sm"
                color="danger"
                className="w-100 h-100"
                style={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
              >
                X
              </CButton>
            </div>
          </div>
        );
      })}
      <CButton
        size="sm"
        color="success"
        className="w-100 my-1"
        onClick={() => createNewModule()}
      >
        Add Module
      </CButton>
    </div>
  );
};

export default CourseModules;
